export const LOAD_FUELING_PROCESSES =
  "supportui/FuelingProcessPage/LOAD_FUELING_PROCESSES";
export const LOAD_FUELING_PROCESSES_SUCCESS =
  "supportui/FuelingProcessPage/LOAD_FUELING_PROCESSES_SUCCESS";
export const LOAD_FUELING_PROCESSES_FAILED =
  "supportui/FuelingProcessPage/LOAD_FUELING_PROCESSES_FAILED";
export const LOAD_FUELING_PROCESS_DETAILS =
  "supportui/FuelingProcessPage/LOAD_FUELING_PROCESS_DETAILS";
export const LOAD_FUELING_PROCESS_DETAILS_SUCCESS =
  "supportui/FuelingProcessPage/LOAD_FUELING_PROCESS_DETAILS_SUCCESS";
export const LOAD_FUELING_PROCESS_DETAILS_FAILED =
  "supportui/FuelingProcessPage/LOAD_FUELING_PROCESS_DETAILS_FAILED";
export const EXPORT_FUELING_PROCESSES_TO_EXCEL =
  "EXPORT_FUELING_PROCESSES_TO_EXCEL";
export const EXPORT_FUELING_PROCESSES_TO_EXCEL_SUCCESS =
  "EXPORT_FUELING_PROCESSES_TO_EXCEL_SUCCESS";
export const EXPORT_FUELING_PROCESSES_TO_EXCEL_FAILED =
  "EXPORT_FUELING_PROCESSES_TO_EXCEL_FAILED";
