import { Layout } from "antd";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { RawIntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";

import NeoHeader from "../../components/Header";
import ProtectedOutlet from "../../components/ProtectedOutlet";
import GlobalStyle from "../../GlobalStyle";
import State from "../../State";
import config from "../../utils/config";
import intl from "../../utils/intl";
import AgreementsPage from "../AgreementsPage";
import CardPage from "../CardPage";
import FuelingProcessPage from "../FuelingProcessesPage";
import NotFoundPage from "../NotFoundPage";
import Callback from "../SessionLogin";
import StationMartsPage from "../StationMartsPage";
import StationsPage from "../StationsPage";
import AppProps from "./props";

const { Header, Content, Footer } = Layout;

const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const BodyLayout = styled(Content)`
  height: 100%;
  padding: 50px;
`;

const HeaderLayout = styled(Header)`
  height: 100%;
`;

const App: React.FC = () => {
  const { isLoggedIn, userName } = useSelector<State, AppProps>(
    (state: State) => {
      let userName = "";
      if (state.session.session) {
        userName = state.session.session.user.email;
      }

      return {
        isLoggedIn: state.session.isLoggedIn,
        userName,
      } as AppProps;
    }
  );
  const location = useLocation();

  return (
    <RawIntlProvider value={intl}>
      <Fragment>
        <AppWrapper>
          <Helmet titleTemplate="%s - NeoSupport" defaultTitle="NeoSupport">
            <meta
              name="description"
              content="Application for support to neo fueling process"
            />
          </Helmet>
          <Layout className="layout">
            <HeaderLayout>
              <NeoHeader userName={userName} location={location} />
            </HeaderLayout>
            <BodyLayout>
              <Routes>
                <Route element={<ProtectedOutlet isLoggedIn={isLoggedIn} />}>
                  <Route path="/callback" element={<Callback />} />
                  <Route path="/card" element={<CardPage />} />
                  <Route path="/processes" element={<FuelingProcessPage />} />
                  <Route path="/stations" element={<StationsPage />} />
                  <Route path="/agreements" element={<AgreementsPage />} />
                  <Route path="/marts" element={<StationMartsPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Routes>
            </BodyLayout>
            <Footer style={{ textAlign: "center" }}>
              Support UI, Version {config.version}
            </Footer>
          </Layout>
          <GlobalStyle />
        </AppWrapper>
      </Fragment>
    </RawIntlProvider>
  );
};

export default App;
