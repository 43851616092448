import { Button, Typography } from "antd";
import React from "react";

import { BottomIndentWrapper } from "../../../spaces";
import intl from "../../../utils/intl";
import SavedProps from "./props";

const Saved: React.FC<SavedProps> = (props: SavedProps) => {
  return (
    <>
      <div>
        <BottomIndentWrapper>
          <Typography.Title level={5}>
            {intl.formatMessage(props.messages.saved, {
              totalCount: props.totalCount,
            })}
          </Typography.Title>
        </BottomIndentWrapper>
      </div>
      <Button id="returnButton" type="primary" onClick={props.completeUpload}>
        {intl.formatMessage(props.messages.return)}
      </Button>
    </>
  );
};

export default Saved;
