import { Space, Spin } from "antd";
import React from "react";
import styled from "styled-components";

const SpinBox = styled(Space)`
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const Spinner: React.FC = () => {
  return (
    <SpinBox>
      <Spin size="large" />
    </SpinBox>
  );
};

export default Spinner;
