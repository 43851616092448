import { startCase } from "lodash";
import React from "react";

import RejectionReasonProps from "./props";

const rejectionReason: React.FC<RejectionReasonProps> = (
  props: RejectionReasonProps
) => {
  const rejectionReasonText = startCase(props.code);
  return <span id={props.id}>{rejectionReasonText}</span>;
};

export default rejectionReason;
