import { Typography } from "antd";
import React from "react";

import { BottomIndentWrapper } from "../../../spaces";
import intl from "../../../utils/intl";
import Spinner from "../../Spinner";
import UploadingProps from "./props";

const Uploading: React.FC<UploadingProps> = (props: UploadingProps) => {
  return (
    <>
      <div>
        <BottomIndentWrapper>
          <Typography.Title level={5}>
            {intl.formatMessage(props.messages.uploading)}
          </Typography.Title>
        </BottomIndentWrapper>
      </div>
      <Spinner />
    </>
  );
};

export default Uploading;
