import { defineMessages } from "react-intl";

const scope = "supportui.components.VendorStationsExport";

export default defineMessages({
  getVendorStations: {
    id: `${scope}.getVendorStations`,
    defaultMessage: "Get stations from vendor:",
  },
  vendor: {
    id: `${scope}.vendor`,
    defaultMessage: "Vendor",
  },
  vendorConfigurationName: {
    id: `${scope}.vendorConfigurationName`,
    defaultMessage: "Vendor configuration name",
  },
  export: {
    id: `${scope}.export`,
    defaultMessage: "Export",
  },
});
