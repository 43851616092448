import { Table } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";

import intl from "../../../../utils/intl";
import messages from "../../messages";
import { Station } from "../../Station";
import StationTableProps from "./props";

const StationTable: React.FC<StationTableProps> = (
  props: StationTableProps
) => {
  const paginationOptions = {
    total: props.stations.length,
    showTotal: (total: number, range: [number, number]): React.ReactNode =>
      `${range[0]}-${range[1]} of ${total}`,
  };

  return (
    <Table
      dataSource={props.stations}
      scroll={{ x: "max-content" }}
      rowKey={"id"}
      pagination={paginationOptions}
    >
      <Column
        width={200}
        sorter={true}
        title={intl.formatMessage(messages.stationHeaderId)}
        render={(record: Station): React.ReactNode => record.id}
      />
      <Column
        width={100}
        title={intl.formatMessage(messages.stationHeaderDeleted)}
        render={(record: Station): React.ReactNode =>
          record.isDeleted && <>&#10004;</>
        }
      />
      <Column
        sorter={true}
        width={300}
        title={intl.formatMessage(messages.stationHeaderName)}
        render={(record: Station): React.ReactNode => record.name}
      />
      <Column
        width={400}
        title={intl.formatMessage(messages.stationHeaderFullAddress)}
        render={(record: Station): React.ReactNode => record.fullAddress}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderLatitude)}
        render={(record: Station): React.ReactNode => record.latitude}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderLongitude)}
        render={(record: Station): React.ReactNode => record.longitude}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderBrandName)}
        render={(record: Station): React.ReactNode => record.brandName}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderBrandCode)}
        render={(record: Station): React.ReactNode => record.brandCode}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderVendorName)}
        render={(record: Station): React.ReactNode => record.vendorName}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderVendorStationId)}
        render={(record: Station): React.ReactNode => record.vendorStationId}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderVendorStationIds)}
        render={(record: Station): React.ReactNode => record.vendorStationIds}
      />
      <Column
        width={200}
        title={intl.formatMessage(
          messages.stationHeaderVendorConfigurationName
        )}
        render={(record: Station): React.ReactNode =>
          record.vendorConfigurationName
        }
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderVendorAdminPhoneNumber)}
        render={(record: Station): React.ReactNode =>
          record.vendorAdminPhoneNumber
        }
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderVendorOtpEnabled)}
        render={(record: Station): React.ReactNode => record.vendorOtpEnabled}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderSupplierId)}
        render={(record: Station): React.ReactNode => record.supplierId}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderCountryCode)}
        render={(record: Station): React.ReactNode => record.countryCode}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderCurrency)}
        render={(record: Station): React.ReactNode => record.currency}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderCaic)}
        render={(record: Station): React.ReactNode => record.caic}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderE100TerminalId)}
        render={(record: Station): React.ReactNode => record.e100TerminalId}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderTimeZone)}
        render={(record: Station): React.ReactNode => record.timeZone}
      />
      <Column
        width={50}
        title={intl.formatMessage(messages.stationTestMode)}
        render={(record: Station): React.ReactNode =>
          record.testMode && <>&#10004;</>
        }
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.stationHeaderRegion)}
        render={(record: Station): React.ReactNode => record.region}
      />
    </Table>
  );
};

export default StationTable;
