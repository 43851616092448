import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";

import State from "../../State";
import { loadSession } from "./actions";

const Callback: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector<State, boolean>(
    (state) => state.session.isLoggedIn
  );
  const location = useLocation();

  const effectCalled = useRef(false);

  useEffect(() => {
    if (effectCalled.current) {
      return;
    }

    effectCalled.current = true;

    if ((location.hash || location.search) && !isLoggedIn) {
      dispatch(loadSession(window.location.href));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return <Navigate to="/processes" />;
  } else {
    return <div />;
  }
};

export default Callback;
