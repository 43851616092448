import { Alert, Divider } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import AppContent from "../../components/AppContent";
import CardSearch from "../../components/CardSearch";
import Title from "../../components/Title";
import State from "../../State";
import intl from "../../utils/intl";
import * as actions from "./actions";
import Card from "./component/Card";
import messages from "./messages";
import CardProps from "./props";

const CardLayout = styled(AppContent)`
  padding: 30px;
`;

const CardWrapper = styled.div`
  padding-top: 20px;
`;

const CardPage: React.FC = () => {
  const dispatch = useDispatch();

  const cardProps = useSelector<State, CardProps>((state) => state.card);

  const search = (cardNumber: string): void => {
    dispatch(actions.searchCards(cardNumber));
  };

  return (
    <div>
      <CardWrapper>
        <CardLayout>
          <CardSearch
            loading={cardProps.loading}
            search={search}
            cardNumber={cardProps.cardNumber}
          />
        </CardLayout>
      </CardWrapper>

      <CardInfoOnCardPage {...cardProps} />
    </div>
  );
};

const CardInfoOnCardPage: React.FC<CardProps> = (props: CardProps) => {
  let cardElement;
  const cardsLength = props.cards.length;
  if (cardsLength > 0) {
    cardElement = (
      <>
        <Title level={4}>
          {intl.formatMessage(messages.cardInformationTitle)}
        </Title>
        {props.cards.map((c, i) => {
          return (
            <span key={c.cardNumber}>
              <Card
                cardNumber={c.cardNumber}
                phoneNumber={c.phoneNumber}
                hasRegisteredSessions={c.hasRegisteredSessions}
              />
              {i !== cardsLength - 1 ? <Divider /> : null}
            </span>
          );
        })}
      </>
    );
  } else if (props.alreadySearched) {
    cardElement = (
      <div id="cardSearchMessage">
        <Alert
          message={intl.formatMessage(messages.cardNotFound)}
          type="warning"
        />
      </div>
    );
  } else {
    return <></>;
  }

  return (
    <CardWrapper>
      <CardLayout>{cardElement}</CardLayout>
    </CardWrapper>
  );
};

export default CardPage;
