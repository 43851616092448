import { Moment } from "moment";

import {
  EXPORT_FUELING_PROCESSES_TO_EXCEL,
  EXPORT_FUELING_PROCESSES_TO_EXCEL_FAILED,
  EXPORT_FUELING_PROCESSES_TO_EXCEL_SUCCESS,
  LOAD_FUELING_PROCESS_DETAILS,
  LOAD_FUELING_PROCESS_DETAILS_FAILED,
  LOAD_FUELING_PROCESS_DETAILS_SUCCESS,
  LOAD_FUELING_PROCESSES,
  LOAD_FUELING_PROCESSES_FAILED,
  LOAD_FUELING_PROCESSES_SUCCESS,
} from "./constants";
import { FuelingProcess, FuelingProcessDetail } from "./state";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export interface LoadFuelingProcessesAction {
  type: typeof LOAD_FUELING_PROCESSES;
  filter: {
    cardNumber?: string;
    dateTimeFrom?: Moment;
    dateTimeTill?: Moment;
    stationId?: string;
  };
}

export interface LoadFuelingProcessAction {
  type: typeof LOAD_FUELING_PROCESS_DETAILS;
  fuelingProcessId: string;
}

export function loadFuelingProcesses(filter: {
  cardNumber?: string;
  dateTimeFrom?: Moment;
  dateTimeTill?: Moment;
  stationId?: string;
}): LoadFuelingProcessesAction {
  return {
    type: LOAD_FUELING_PROCESSES,
    filter,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function fuelingProcessesLoadingError() {
  return {
    type: LOAD_FUELING_PROCESSES_FAILED,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function fuelingProcessesLoaded(
  fuelingProcesses: Array<FuelingProcessDetail>
) {
  return {
    type: LOAD_FUELING_PROCESSES_SUCCESS,
    fuelingProcesses: fuelingProcesses,
  };
}

export function loadFuelingProcessDetail(
  fuelingProcessId: string
): LoadFuelingProcessAction {
  return {
    type: LOAD_FUELING_PROCESS_DETAILS,
    fuelingProcessId: fuelingProcessId,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function fuelingProcessDetailsLoaded(fuelingProcess: FuelingProcess) {
  return {
    type: LOAD_FUELING_PROCESS_DETAILS_SUCCESS,
    fuelingProcess: fuelingProcess,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function fuelingProcessDetailsLoadingError() {
  return {
    type: LOAD_FUELING_PROCESS_DETAILS_FAILED,
  };
}

export interface ExportFuelingProcessesToExcelAction {
  type: typeof EXPORT_FUELING_PROCESSES_TO_EXCEL;
  filter: {
    cardNumber?: string;
    dateTimeFrom?: Moment;
    dateTimeTill?: Moment;
    stationId?: string;
  };
}

export function exportFuelingProcessesToExcel(filter: {
  cardNumber?: string;
  dateTimeFrom?: Moment;
  dateTimeTill?: Moment;
  stationId?: string;
}): ExportFuelingProcessesToExcelAction {
  return {
    type: EXPORT_FUELING_PROCESSES_TO_EXCEL,
    filter,
  };
}

export function exportFuelingProcessesToExcelSuccess(downloadUrl: string) {
  return {
    type: EXPORT_FUELING_PROCESSES_TO_EXCEL_SUCCESS,
    downloadUrl: downloadUrl,
  };
}

export function exportFuelingProcessesToExcelFailed() {
  return {
    type: EXPORT_FUELING_PROCESSES_TO_EXCEL_FAILED,
  };
}
