import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import intl from "../../utils/intl";
import messages from "./messages";
import MenuProps from "./props";

const NeoMenu: React.FC<MenuProps> = (props: MenuProps) => {
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[props.location.pathname]}
      selectedKeys={[props.location.pathname]}
    >
      <Menu.Item key="/processes">
        <Link to="/processes" id="processesLink">
          {intl.formatMessage(messages.fuelingProcesses)}
        </Link>
      </Menu.Item>
      <Menu.Item key="/card">
        <Link id="cardLink" to="/card">
          {intl.formatMessage(messages.card)}
        </Link>
      </Menu.Item>
      <Menu.Item key="/stations">
        <Link to="/stations" id="stationsLink">
          {intl.formatMessage(messages.stations)}
        </Link>
      </Menu.Item>
      <Menu.Item key="/agreements">
        <Link to="/agreements" id="agreementsLink">
          {intl.formatMessage(messages.agreements)}
        </Link>
      </Menu.Item>
      <Menu.Item key="/marts">
        <Link to="/marts" id="stationMartsLink">
          {intl.formatMessage(messages.stationMarts)}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default NeoMenu;
