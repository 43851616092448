import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import AppContent from "../../components/AppContent";
import StationsDownloading from "../../components/import-export/Downloading";
import Initial from "../../components/import-export/Initial";
import Saved from "../../components/import-export/Saved";
import Saving from "../../components/import-export/Saving";
import Uploading from "../../components/import-export/Uploading";
import ValidationFailure from "../../components/import-export/ValidationFailure";
import ValidationSuccess from "../../components/import-export/ValidationSuccess";
import State from "../../State";
import * as actions from "./actions";
import StationMartsTable from "./components/StationTable";
import messages from "./messages";
import StationMartsPageState from "./state";

const StationMartsWrapper = styled.div`
  padding-top: 20px;
`;

const StationMartsLayout = styled(AppContent)`
  padding: 30px;
`;

function getContent(
  state: StationMartsPageState,
  uploadStationMarts: (file: File) => void,
  completeStationMartsUpload: () => void,
  saveStationMarts: (stationImportId: string) => void,
  downloadStationMarts: () => void
): ReactNode {
  switch (state.type) {
    case "initial":
      return (
        <Initial upload={uploadStationMarts} download={downloadStationMarts} />
      );
    case "saved":
      return (
        <Saved
          messages={{
            saved: messages.stationMartsSaved,
            return: messages.returnToStationMarts,
          }}
          completeUpload={completeStationMartsUpload}
          totalCount={state.totalCount}
        />
      );
    case "saving":
      return (
        <Saving
          messages={{
            saving: messages.savingStationMarts,
          }}
        />
      );
    case "uploading":
      return (
        <Uploading
          messages={{
            uploading: messages.uploadingStationMarts,
          }}
        />
      );
    case "upload-succeeded":
      return (
        <ValidationSuccess
          messages={{
            toBeSaved: messages.stationMartsToBeSaved,
            returnTo: messages.returnToStationMarts,
            save: messages.save,
          }}
          validatedData={
            <StationMartsTable stationMarts={state.stationMarts} />
          }
          importId={state.stationMartsImportId}
          return={completeStationMartsUpload}
          save={saveStationMarts}
        />
      );
    case "upload-failed":
      return (
        <ValidationFailure
          messages={{
            uploadFailed: messages.stationMartsUploadFailed,
            errors: messages.errors,
            returnTo: messages.returnToStationMarts,
          }}
          errors={state.errors}
          complete={completeStationMartsUpload}
        />
      );
    case "downloading":
      return <StationsDownloading downloading={true} />;
  }
}

const StationMartsPage: React.FC = () => {
  const dispatch = useDispatch();

  const uploadStationMarts = (file: File): void => {
    dispatch(actions.uploadStationMarts(file as File));
  };

  const completeStationMartsUpload = (): void => {
    dispatch(actions.completeStationMartsUpload());
  };

  const saveStationMarts = (stationImportId: string): void => {
    dispatch(actions.saveStationMarts(stationImportId));
  };

  const downloadStationMarts = (): void => {
    dispatch(actions.downloadStationMarts());
  };

  const state = useSelector<State, StationMartsPageState>(
    (state) => state.stationMarts
  );

  return (
    <StationMartsWrapper>
      <StationMartsLayout>
        {getContent(
          state,
          uploadStationMarts,
          completeStationMartsUpload,
          saveStationMarts,
          downloadStationMarts
        )}
      </StationMartsLayout>
    </StationMartsWrapper>
  );
};

export default StationMartsPage;
