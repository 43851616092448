export class Agreement {
  cardIssuerId: string;
  supplierId: string;

  constructor(cardIssuerId: string, supplierId: string) {
    this.cardIssuerId = cardIssuerId;
    this.supplierId = supplierId;
  }

  equals(other: Agreement): boolean {
    return (
      this.cardIssuerId === other.cardIssuerId &&
      this.supplierId === other.supplierId
    );
  }

  static defaultSort(a: Agreement, b: Agreement): number {
    let result = a.cardIssuerId.localeCompare(b.cardIssuerId);
    if (result === 0) {
      result = a.supplierId.localeCompare(b.supplierId);
    }
    return result;
  }
}
