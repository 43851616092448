export const LOAD_AGREEMENTS = "supportui/AgreementsPage/LOAD_AGREEMENTS";
export const AGREEMENTS_LOADED = "supportui/AgreementsPage/AGREEMENTS_LOADED";
export const DELETE_AGREEMENT = "supportui/AgreementsPage/DELETE_AGREEMENT";
export const AGREEMENT_DELETE_SUCCESS =
  "supportui/AgreementsPage/AGREEMENT_DELETE_SUCCESS";
export const AGREEMENT_DELETE_FAILED =
  "supportui/AgreementsPage/AGREEMENT_DELETE_FAILED";
export const SHOW_CREATE_AGREEMENT_DIALOG =
  "supportui/AgreementsPage/SHOW_CREATE_AGREEMENT_DIALOG";
export const HIDE_CREATE_AGREEMENT_DIALOG =
  "supportui/AgreementsPage/HIDE_CREATE_AGREEMENT_DIALOG";
export const CREATE_AGREEMENT = "supportui/AgreementsPage/CREATE_AGREEMENT";
export const CREATE_AGREEMENT_SUCCESS =
  "supportui/AgreementsPage/CREATE_AGREEMENT_SUCCESS";
export const CREATE_AGREEMENT_FAILED =
  "supportui/AgreementsPage/CREATE_AGREEMENT_FAILED";
export const EXPORT_AGREEMENTS = "supportui/AgreementsPage/EXPORT_AGREEMENTS";
export const AGREEMENTS_EXPORTED =
  "supportui/AgreementsPage/AGREEMENTS_EXPORTED";
