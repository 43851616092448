import { Button, Typography } from "antd";
import React from "react";

import { BottomIndentWrapper, RightIndentWrapper } from "../../../spaces";
import intl from "../../../utils/intl";
import ValidationSuccessProps from "./props";

const ValidationSuccess: React.FC<ValidationSuccessProps> = (
  props: ValidationSuccessProps
) => {
  return (
    <>
      <div>
        <BottomIndentWrapper>
          <Typography.Title level={5}>
            {intl.formatMessage(props.messages.toBeSaved)}
          </Typography.Title>
        </BottomIndentWrapper>
      </div>

      {props.validatedData}

      <div>
        <RightIndentWrapper>
          <Button onClick={props.return}>
            {intl.formatMessage(props.messages.returnTo)}
          </Button>
        </RightIndentWrapper>
        <Button
          type="primary"
          id="saveButton"
          onClick={(): void => props.save(props.importId)}
        >
          {intl.formatMessage(props.messages.save)}
        </Button>
      </div>
    </>
  );
};

export default ValidationSuccess;
