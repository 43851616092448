import { Empty } from "antd";
import React from "react";
import styled from "styled-components";

import Spinner from "../../../../components/Spinner";
import FuelingProcessDetailBasicInfo from "../FuelingProcessDetailBasicInfo";
import FuelingProcessDetailEvents from "../FuelingProcessDetailEvents";
import FuelingProcessesDetailProps from "./props";

const Layout = styled.div`
  padding: 0 0 20px;
`;

const ProcessTableDetail: React.FC<FuelingProcessesDetailProps> = (
  props: FuelingProcessesDetailProps
) => {
  if (props.loading) return <Spinner />;

  if (props.fuelingProcess) {
    const fuelingProcess = props.fuelingProcess ?? {};
    return (
      <>
        <Layout>
          <FuelingProcessDetailBasicInfo details={fuelingProcess.details} />
        </Layout>
        <Layout>
          <FuelingProcessDetailEvents events={fuelingProcess.events} />
        </Layout>
      </>
    );
  }

  return <Empty />;
};

export default ProcessTableDetail;
