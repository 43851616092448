import { defineMessages } from "react-intl";

const scope = "supportui.components.AgreementsPage.CreateButton";

export default defineMessages({
  createAgreement: {
    id: `${scope}.createAgreement`,
    defaultMessage: "Create",
  },
});
