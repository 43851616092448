// eslint-disable-next-line @typescript-eslint/no-empty-interface
import {
  ACTION_COMPLETE_STATION_MARTS_UPLOAD,
  ACTION_DOWNLOAD_STATION_MARTS,
  ACTION_DOWNLOAD_STATION_MARTS_STARTED,
  ACTION_SAVE_STATION_MARTS,
  ACTION_STATION_MARTS_SAVED,
  ACTION_UPLOAD_STATION_MARTS,
  ACTION_UPLOAD_STATION_MARTS_FAILED,
  ACTION_UPLOAD_STATION_MARTS_SUCCEEDED,
} from "./constants";
import { StationMart } from "./StationMart";

export interface UploadStationMartsAction {
  type: typeof ACTION_UPLOAD_STATION_MARTS;
  content: File;
}

export interface StationMartsUploadSucceededAction {
  type: typeof ACTION_UPLOAD_STATION_MARTS_SUCCEEDED;
  stationMarts: Array<StationMart>;
  stationMartsImportId: string;
}

export interface StationMartsUploadFailedAction {
  type: typeof ACTION_UPLOAD_STATION_MARTS_FAILED;
  errors: Array<string>;
}

export interface CompleteStationMartsUploadAction {
  type: typeof ACTION_COMPLETE_STATION_MARTS_UPLOAD;
}

export interface SaveStationMartsAction {
  type: typeof ACTION_SAVE_STATION_MARTS;
  stationMartsImportId: string;
}

export interface StationMartsSavedAction {
  type: typeof ACTION_STATION_MARTS_SAVED;
}

export interface DownloadStationMartsAction {
  type: typeof ACTION_DOWNLOAD_STATION_MARTS;
}

export interface DownloadStationMartsStartedAction {
  type: typeof ACTION_DOWNLOAD_STATION_MARTS_STARTED;
}

export function uploadStationMarts(content: File): UploadStationMartsAction {
  return {
    type: ACTION_UPLOAD_STATION_MARTS,
    content: content,
  };
}

export function stationMartsUploadSucceeded(
  stationMarts: Array<StationMart>,
  stationMartsImportId: string
): StationMartsUploadSucceededAction {
  return {
    type: ACTION_UPLOAD_STATION_MARTS_SUCCEEDED,
    stationMarts: stationMarts,
    stationMartsImportId: stationMartsImportId,
  };
}

export function stationMartsUploadFailed(
  errors: Array<string>
): StationMartsUploadFailedAction {
  return {
    type: ACTION_UPLOAD_STATION_MARTS_FAILED,
    errors: errors,
  };
}

export function saveStationMarts(
  stationMartImportId: string
): SaveStationMartsAction {
  return {
    type: ACTION_SAVE_STATION_MARTS,
    stationMartsImportId: stationMartImportId,
  };
}

export function completeStationMartsUpload(): CompleteStationMartsUploadAction {
  return {
    type: ACTION_COMPLETE_STATION_MARTS_UPLOAD,
  };
}

export function stationMartsSaved(): StationMartsSavedAction {
  return {
    type: ACTION_STATION_MARTS_SAVED,
  };
}

export function downloadStationMarts(): DownloadStationMartsAction {
  return {
    type: ACTION_DOWNLOAD_STATION_MARTS,
  };
}

export function downloadStationMartsStarted(): DownloadStationMartsStartedAction {
  return {
    type: ACTION_DOWNLOAD_STATION_MARTS_STARTED,
  };
}
