import { defineMessages } from "react-intl";

const scope = "supportui.components.AgreementsPage.CreateAgreementDialog";

export default defineMessages({
  createAgreement: {
    id: `${scope}.createAgreement`,
    defaultMessage: "Create Supplier Agreement",
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: "Create",
  },
  cardIssuerId: {
    id: `${scope}.cardIssuerId`,
    defaultMessage: "Card Issuer",
  },
  pleaseInputCardIssuerId: {
    id: `${scope}.pleasInputCardIssuerId`,
    defaultMessage: "Please input Card Issuer",
  },
  supplierId: {
    id: `${scope}.supplierId`,
    defaultMessage: "Supplier",
  },
  pleaseInputSupplierId: {
    id: `${scope}.pleaseInputSupplierId`,
    defaultMessage: "Please input Supplier",
  },
});
