import { notification } from "antd";

const showError = (message: string): void => {
  notification.error({
    message: "Error",
    description: message,
  });
};

export default { showError };
