export interface CognitoConfiguration {
  userPoolId: string;
  clientId: string;
  appWebDomain: string;
  tokenScopesArray: Array<string>;
  redirectUriSignIn: string;
  redirectUriSignOut: string;
}
export interface Configuration {
  cognito: CognitoConfiguration;
  version: string;
}

const appAppBaseUrl: string =
  process.env.REACT_APP_DOMAIN_NAME === undefined
    ? ""
    : process.env.REACT_APP_DOMAIN_NAME;

const config: Configuration = {
  cognito: {
    userPoolId:
      process.env.REACT_APP_COGNITO_USER_POOL_ID === undefined
        ? ""
        : process.env.REACT_APP_COGNITO_USER_POOL_ID,
    clientId:
      process.env.REACT_APP_COGNITO_CLIENT_ID === undefined
        ? ""
        : process.env.REACT_APP_COGNITO_CLIENT_ID,
    appWebDomain:
      process.env.REACT_APP_COGNITO_APP_WEB_DOMAIN === undefined
        ? ""
        : process.env.REACT_APP_COGNITO_APP_WEB_DOMAIN,
    tokenScopesArray: ["aws.cognito.signin.user.admin"],
    redirectUriSignIn: appAppBaseUrl + "/callback",
    redirectUriSignOut: appAppBaseUrl,
  },
  version:
    process.env.REACT_APP_VERSION === undefined
      ? ""
      : process.env.REACT_APP_VERSION,
};

export default config;
