import { Col, Row } from "antd";
import React from "react";

import DescriptionsRowProps from "./props";

const DescriptionsRow: React.FC<DescriptionsRowProps> = (
  props: DescriptionsRowProps
) => {
  if (props.visible ?? true)
    return (
      <Row gutter={[4, 0]}>
        <Col>{props.label}:</Col>
        <Col flex="auto">{props.children}</Col>
      </Row>
    );

  return <></>;
};

export default DescriptionsRow;
