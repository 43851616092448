export interface ProductIdToProductNameDictionary {
  [vendor: string]: { [productId: string]: string | undefined } | undefined;
}

const productIdToProductNameDictionary: ProductIdToProductNameDictionary = {
  MobyPay: {
    Diesel: "Diesel",
    DieselSpecialTruck: "Diesel Q +",
    NormalUnleaded: "EuroSuper 95",
    AdBlue: "AdBlue",
    SuperUnleaded: "Superplus 98",
  },
  TwoIOnline: {
    "00001": "GAS A",
    "00002": "GAS B",
    "00015": "AdBlue",
    "00020": "SP95",
    "00025": "SP98",
  },
  Way4: {
    "01": "ДТ",
    "02": "ДТ Зимнее",
    "03": "ДТ Арктическое",
    "04": "ДТ Межсезонное",
    "05": "ДТ Фирм",
    "08": "АИ-80-К5",
    "09": "АИ-92-К5",
    "10": "АИ-92-К5 Фирм",
    "11": "АИ-95-К5",
    "12": "АИ-95-К5 Фирм",
    "13": "АИ-98-К5",
    "14": "АИ-98-К5 Фирм",
    "30": "АИ-100-К5",
    "31": "АИ-100-К5 Фирм",
    "32": "AdBlue",
    "17": "Метан",
    "18": "Пропан",
  },
  /*eslint-disable */
  GazpromNeft: {
    diesel: "ДТ",
    diesel_premium: "ДТ Фирм",
    a80: "АИ-80-К5",
    a92: "АИ-92-К5",
    a92_premium: "АИ-92-К5 Фирм",
    a95: "АИ-95-К5",
    a95_premium: "АИ-95-К5 Фирм",
    a98: "АИ-98-К5",
    a98_premium: "АИ-98-К5 Фирм",
    a100: "АИ-100-К5",
    a100_premium: "АИ-100-К5 Фирм",
    propane: "Пропан",
  },
  /*eslint-enable */
  Surgut: {
    "0": "ДТЛ",
    "2": "ДТЗ",
    "4": "ДТЕ",
    "22": "ДТМ",
    "14": "АИ-80-К5",
    "12": "АИ-92-К5",
    "10": "АИ-95-К5",
    "8": "АИ-98-К5",
    "16": "AdBlue",
  },
  Topaz: {
    DieselRu: "ДТ",
    DieselWinter: "ДТЗ",
    DieselTurbo: "ДТ Фирм",
    DieselArctic: "ДТ Арктический",
    DieselAllSeason: "ДТМ",
    Petrol80: "АИ-80",
    Petrol92: "АИ-92",
    Petrol92Branded: "АИ-92 Фирм",
    Petrol95: "АИ-95",
    Petrol95Branded: "АИ-95 Фирм",
    Petrol98: "АИ-98",
    Petrol98Branded: "АИ-98 Фирм",
    Petrol100: "АИ-100-К5",
    Petrol100Branded: "АИ-100 Фирм",
    AdBlue: "AdBlue",
    GasPropane: "Пропан",
    GasMethane: "Метан",
  },
  SupplierApi: {
    dieselRu: "ДТ",
    diesel: "Diesel",
    dieselEuro: "Diesel",
    dieselTurbo: "Diesel Branded",
    dieselWinter: "Diesel Winter",
    dieselArctic: "Diesel Arctic",
    dieselAllSeason: "Diesel Off-season",
    petrol80: "АИ-80",
    petrol92: "Petrol 92",
    petrol92Branded: "Petrol 92 Branded",
    petrol95: "Petrol 95",
    petrol95Branded: "Petrol 95 Branded",
    petrol98: "Petrol 98",
    petrol98Branded: "Petrol 98 Branded",
    petrol100: "Petrol 100",
    petrol100Branded: "Petrol 100 Branded",
    adBlue: "AdBlue",
    gasPropane: "Propane",
    gasMethane: "Methane",
    windshieldWasherSummer: "Windshield Washer Summer",
    windshieldWasherWinter: "Windshield Washer Winter",
  },
  DecentralizedSupplierApi: {
    dieselRu: "ДТ",
    diesel: "Diesel",
    dieselEuro: "Diesel",
    dieselTurbo: "Diesel Branded",
    dieselWinter: "Diesel Winter",
    dieselArctic: "Diesel Arctic",
    dieselAllSeason: "Diesel Off-season",
    petrol80: "АИ-80",
    petrol92: "Petrol 92",
    petrol92Branded: "Petrol 92 Branded",
    petrol95: "Petrol 95",
    petrol95Branded: "Petrol 95 Branded",
    petrol98: "Petrol 98",
    petrol98Branded: "Petrol 98 Branded",
    petrol100: "Petrol 100",
    petrol100Branded: "Petrol 100 Branded",
    adBlue: "AdBlue",
    gasPropane: "Propane",
    gasMethane: "Methane",
  },
};

export const getProductName = (vendor: string, productId: string): string =>
  (productIdToProductNameDictionary[vendor] || {})[productId] || productId;
