import { CreditCardOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import { Moment } from "moment";
import React from "react";
import styled from "styled-components";

import intl from "../../../../utils/intl";
import { ExportToExcel } from "../ExportToExcel";
import messages from "./messages";
import FuelingProcessFilterProps, { FilterValues } from "./props";

const CardNumberInput = styled(Input)`
  width: 230px;
`;

const StationIdInput = styled(Input)`
  width: 150px;
`;

const FuelingProcessFilter: React.FC<FuelingProcessFilterProps> = (
  props: FuelingProcessFilterProps
) => {
  const [form] = Form.useForm();

  const submitSearch = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    props.search(normalizeInputValues(values));
  };

  const submitExportToExcel = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    props.exportToExcel(normalizeInputValues(values));
  };

  const initialValues = {
    fromDate: props.filter.dateTimeFrom,
    tillDate: props.filter.dateTimeTill,
    cardNumber: props.filter.cardNumber ? props.filter.cardNumber : "",
    stationId: props.filter.stationId,
  };

  return (
    <>
      <Form initialValues={initialValues} form={form}>
        <Row gutter={24}>
          <Col>
            <Form.Item
              label={intl.formatMessage(messages.cardNumberLabel)}
              name="cardNumber"
              rules={[
                {
                  message: intl.formatMessage(messages.inputCardNumberMessage),
                  validator: (_, value): Promise<string | void> => {
                    if (
                      !new RegExp(/^([0-9]|\s|-)*$/).test(value) ||
                      (value.length !== 0 &&
                        value.replace(/\D/g, "").length === 0)
                    ) {
                      return Promise.reject(
                        intl.formatMessage(messages.inputCardNumberMessage)
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <CardNumberInput
                allowClear
                prefix={<CreditCardOutlined />}
                placeholder={intl.formatMessage(messages.cardNumberPlaceholder)}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label={intl.formatMessage(messages.fromDateLabel)}
              name="fromDate"
            >
              <DatePicker allowClear={false} />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label={intl.formatMessage(messages.tillDateLabel)}
              name="tillDate"
            >
              <DatePicker />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label={intl.formatMessage(messages.stationIdLabel)}
              name="stationId"
            >
              <StationIdInput
                allowClear
                prefix={<EnvironmentOutlined />}
                placeholder={intl.formatMessage(messages.stationIdPlaceholder)}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button
              id="searchButton"
              type="primary"
              loading={props.loading}
              onClick={submitSearch}
            >
              {intl.formatMessage(messages.searchButtonText)}
            </Button>
          </Col>

          <Col>
            <ExportToExcel
              exportingToExcel={props.exportingToExcel}
              exportToExcel={submitExportToExcel}
              downloadUrl={props.downloadUrl}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const normalizeInputValues = (values: {
  cardNumber?: string;
  fromDate?: Moment;
  tillDate?: Moment;
  stationId?: string;
}): FilterValues => {
  const cardNumberDigits = values.cardNumber?.replace(/\D/g, "") || undefined;
  const utcDateFrom = values.fromDate
    ?.clone()
    .startOf("day")
    .utcOffset("Z", true);

  const utcDateTill = values.tillDate
    ?.clone()
    .startOf("day")
    .add(1, "day")
    .utcOffset("Z", true);

  const trimmedStationId = values.stationId?.trim();

  return {
    cardNumber: cardNumberDigits,
    dateTimeFrom: utcDateFrom,
    dateTimeTill: utcDateTill,
    stationId: trimmedStationId,
  };
};

export default FuelingProcessFilter;
