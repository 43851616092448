import { Form, Input, Modal } from "antd";
import React from "react";

import intl from "../../../../utils/intl";
import { Agreement } from "../../Agreement";
import messages from "./messages";
import CreateAgreementProps from "./props";

const CreateAgreementDialog: React.FC<CreateAgreementProps> = (
  props: CreateAgreementProps
) => {
  const [form] = Form.useForm();

  const validateAndCreate = async () => {
    let fields: {
      cardIssuerId: string;
      supplierId: string;
    };
    try {
      fields = await form.validateFields();
    } catch (e) {
      console.log("Validation error: ", e);
      return;
    }
    props.create(
      new Agreement(fields.cardIssuerId.trim(), fields.supplierId.trim())
    );
  };

  return (
    <Modal
      title={intl.formatMessage(messages.createAgreement)}
      okText={intl.formatMessage(messages.create)}
      visible={props.visible}
      onOk={validateAndCreate}
      confirmLoading={props.creating}
      cancelButtonProps={{ disabled: props.creating }}
      onCancel={props.cancel}
      closable={!props.creating}
      maskClosable={!props.creating}
      afterClose={form.resetFields}
    >
      <Form form={form} autoComplete="off" layout="vertical">
        <Form.Item
          label={intl.formatMessage(messages.cardIssuerId)}
          name="cardIssuerId"
          rules={[
            {
              required: true,
              whitespace: true,
              message: intl.formatMessage(messages.pleaseInputCardIssuerId),
            },
          ]}
        >
          <Input maxLength={100} readOnly={props.creating} />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage(messages.supplierId)}
          name="supplierId"
          rules={[
            {
              required: true,
              whitespace: true,
              message: intl.formatMessage(messages.pleaseInputSupplierId),
            },
          ]}
        >
          <Input maxLength={100} readOnly={props.creating} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAgreementDialog;
