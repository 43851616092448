import { defineMessages } from "react-intl";

const scope = "supportui.containers.NotFoundPage";

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Page not found.",
  },
});
