import { defineMessages } from "react-intl";

const scope = "supportui.containers.FuelingProcessTable";

export default defineMessages({
  cardNumberTitle: {
    id: `${scope}.cardNumberTitle`,
    defaultMessage: "Card Number",
  },
  createTimeTitle: {
    id: `${scope}.createTimeTitle`,
    defaultMessage: "Create Time, UTC",
  },
  stateTitle: {
    id: `${scope}.stateTitle`,
    defaultMessage: "State",
  },
  stationIdTitle: {
    id: `${scope}.stationIdTitle`,
    defaultMessage: "Station",
  },
  productIdTitle: {
    id: `${scope}.productIdTitle`,
    defaultMessage: "Product",
  },
  receiptPriceTitle: {
    id: `${scope}.receiptPrice`,
    defaultMessage: "Price",
  },
  requestedAmountTitle: {
    id: `${scope}.requestedAmountTitle`,
    defaultMessage: "Requested Amount",
  },
  receiptAmountTitle: {
    id: `${scope}.receiptAmountTitle`,
    defaultMessage: "Receipt Amount",
  },
  receiptQuantityTitle: {
    id: `${scope}.receiptQuantityTitle`,
    defaultMessage: "Quantity",
  },
  rejectionReasonTitle: {
    id: `${scope}.rejectionReasonTitle`,
    defaultMessage: "Rejection Reason",
  },
  actionTitle: {
    id: `${scope}.actionTitle`,
    defaultMessage: "Action",
  },
  viewDetails: {
    id: `${scope}.viewDetails`,
    defaultMessage: "View details",
  },
  clientIdTitle: {
    id: `${scope}.clientIdTitle`,
    defaultMessage: "Client",
  },
  partnerIdTitle: {
    id: `${scope}.partnerIdTitle`,
    defaultMessage: "Partner",
  },
  partnerRefuelingIdTitle: {
    id: `${scope}.partnerRefuelingIdTitle`,
    defaultMessage: "Partner Refueling ID",
  },
  supplierIdTitle: {
    id: `${scope}.supplierIdTitle`,
    defaultMessage: "Supplier",
  },
});
