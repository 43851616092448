import {
  SEARCH_CARDS,
  SEARCH_CARDS_FAILED,
  SEARCH_CARDS_SUCCEEDED,
} from "./constants";
import { Card } from "./state";

export interface SearchCardsAction {
  type: typeof SEARCH_CARDS;
  cardNumber: string;
}

export function searchCards(cardNumber: string): SearchCardsAction {
  return {
    type: SEARCH_CARDS,
    cardNumber: cardNumber,
  };
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function searchCardsFailed() {
  return {
    type: SEARCH_CARDS_FAILED,
  };
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function searchCardsSucceeded(cards: Card[]) {
  return {
    type: SEARCH_CARDS_SUCCEEDED,
    cards,
  };
}
