import { Button, Space } from "antd";
import React from "react";
import styled from "styled-components";

import intl from "../../../../utils/intl";
import messages from "./messages";
import CreateButtonProps from "./props";

const CreateButtonWrapper = styled(Space)`
  margin-bottom: 16px;
`;

const CreateButton: React.FC<CreateButtonProps> = (
  props: CreateButtonProps
) => {
  return (
    <CreateButtonWrapper>
      <Button id="createAgreement" onClick={props.onClick}>
        {intl.formatMessage(messages.createAgreement)}
      </Button>
    </CreateButtonWrapper>
  );
};

export default CreateButton;
