import { Button } from "antd";
import React, { useEffect, useRef } from "react";

import intl from "../../../../utils/intl";
import messages from "./messages";
import ExportToExcelProps from "./props";

export const ExportToExcel: React.FC<ExportToExcelProps> = (
  props: ExportToExcelProps
) => {
  const downloadUrl: React.MutableRefObject<string | undefined> = useRef();

  useEffect(() => {
    if (props.downloadUrl && props.downloadUrl !== downloadUrl.current) {
      const link = document.createElement("a");
      link.href = props.downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      downloadUrl.current = props.downloadUrl;
    }
  }, [props.downloadUrl]);

  return (
    <Button
      id="exportToExcel"
      onClick={props.exportToExcel}
      loading={props.exportingToExcel}
    >
      {intl.formatMessage(messages.exportButtonText)}
    </Button>
  );
};
