import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";

import intl from "../../utils/intl";
import messages from "./messages";
import FuelingProcessStatusProps from "./props";

const localizeFuelingProcessStateName = (
  fuelingProcessState: string
): string => {
  if (fuelingProcessState in messages) {
    return intl.formatMessage(
      messages[fuelingProcessState as keyof typeof messages]
    );
  } else {
    return fuelingProcessState;
  }
};

const FuelingProcessStatus: React.FC<FuelingProcessStatusProps> = (
  props: FuelingProcessStatusProps
) => {
  const stateName = localizeFuelingProcessStateName(props.state);

  switch (props.state) {
    case "created":
    case "paymentPreAuthorized":
    case "pumpReady":
    case "tankingStarted":
    case "receiptReceived":
      return (
        <Tag color="processing" icon={<SyncOutlined spin />} id={props.id}>
          {stateName}
        </Tag>
      );
    case "paymentConfirmed":
      return (
        <Tag color="success" icon={<CheckCircleOutlined />} id={props.id}>
          {stateName}
        </Tag>
      );
    case "rejected":
    case "paymentCancelled":
      return (
        <Tag color="warning" icon={<ExclamationCircleOutlined />} id={props.id}>
          {stateName}
        </Tag>
      );
    case "failed":
      return (
        <Tag color="error" icon={<CloseCircleOutlined />} id={props.id}>
          {stateName}
        </Tag>
      );
    default:
      return (
        <Tag color="default" id={props.id}>
          {stateName}
        </Tag>
      );
  }
};

export default FuelingProcessStatus;
