import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React from "react";

import messages from "../../../containers/StationMartsPage/messages";
import { BottomIndentWrapper } from "../../../spaces";
import intl from "../../../utils/intl";
import StationsDownloadingProps from "./props";

const Downloading: React.FC<StationsDownloadingProps> = (
  props: StationsDownloadingProps
) => {
  return (
    <>
      <Typography.Title level={5}>
        {intl.formatMessage(messages.chooseOperation)}
      </Typography.Title>
      <BottomIndentWrapper size="large">
        <Button type="primary" id="uploadButton" icon={<UploadOutlined />}>
          {intl.formatMessage(messages.upload)}
        </Button>
        <Button
          id="downloadAllButton"
          type="primary"
          loading={props.downloading}
          icon={<DownloadOutlined />}
        >
          {intl.formatMessage(messages.export)}
        </Button>
      </BottomIndentWrapper>
    </>
  );
};

export default Downloading;
