import { Table } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";

import Spinner from "../../../../components/Spinner";
import intl from "../../../../utils/intl";
import { Agreement } from "../../Agreement";
import ActionsCell from "../ActionsCell";
import messages from "./messages";
import AgreementTableProps from "./props";

const cardIssuerIdSorter = (a: Agreement, b: Agreement): number =>
  a.cardIssuerId.localeCompare(b.cardIssuerId);

const supplierIdSorter = (a: Agreement, b: Agreement): number =>
  a.supplierId.localeCompare(b.supplierId);

const AgreementTable: React.FC<AgreementTableProps> = (
  props: AgreementTableProps
) => {
  if (props.loading) return <Spinner />;

  return (
    <Table
      dataSource={props.agreements}
      rowKey={(r) => `${r.supplierId}_${r.cardIssuerId}`}
    >
      <Column
        title={intl.formatMessage(messages.cardIssuerIdTitle)}
        sorter={cardIssuerIdSorter}
        render={(record: Agreement): React.ReactNode => record.cardIssuerId}
        width="50%"
      />
      <Column
        title={intl.formatMessage(messages.supplierIdTitle)}
        sorter={supplierIdSorter}
        render={(record: Agreement): React.ReactNode => record.supplierId}
        width="50%"
      />
      <Column
        title={intl.formatMessage(messages.actionTitle)}
        render={(record: Agreement): React.ReactNode => (
          <ActionsCell
            record={record}
            deleting={props.deletingAgreement === record}
            delete={props.delete}
          />
        )}
      />
    </Table>
  );
};

export default AgreementTable;
