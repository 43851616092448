import { defineMessages } from "react-intl";

const scope = "supportui.components.CardSearch";

export default defineMessages({
  cardNumberLabel: {
    id: `${scope}.cardNumberLabel`,
    defaultMessage: "Card number",
  },
  cardNumberPlaceholder: {
    id: `${scope}.cardNumberPlaceholder`,
    defaultMessage: "Enter up to 19 digits",
  },
  inputCardNumberMessage: {
    id: `${scope}.inputCardNumberMessage`,
    defaultMessage: "Please input card number (min 5 digits)",
  },
  searchButtonText: {
    id: `${scope}.searchButtonText`,
    defaultMessage: "Search",
  },
});
