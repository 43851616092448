import { defineMessages } from "react-intl";

const scope = "supportui.components.Stations";

export default defineMessages({
  errors: {
    id: `${scope}.errors`,
    defaultMessage: "Errors",
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: "Import",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save",
  },
  returnToStations: {
    id: `${scope}.returnToStations`,
    defaultMessage: "Return to stations",
  },
  savingStations: {
    id: `${scope}.savingStations`,
    defaultMessage: "Saving stations...",
  },
  uploadingStations: {
    id: `${scope}.uploading`,
    defaultMessage: "Importing stations...",
  },
  stationsUploadFailed: {
    id: `${scope}.stationsUploadFailed`,
    defaultMessage: "Stations import failed",
  },
  stationsToBeSaved: {
    id: `${scope}.stationsToBeSaved`,
    defaultMessage: "Stations to be saved",
  },
  stationsSaved: {
    id: `${scope}.stationsSaved`,
    defaultMessage: "{totalCount} station(s) have been successfully saved",
  },
  stationHeaderId: {
    id: `${scope}.StationHeaderId`,
    defaultMessage: "StationId",
  },
  stationHeaderDeleted: {
    id: `${scope}.StationHeaderDeleted`,
    defaultMessage: "Deleted",
  },
  stationHeaderName: {
    id: `${scope}.StationHeaderName`,
    defaultMessage: "Name",
  },
  stationHeaderFullAddress: {
    id: `${scope}.StationHeaderFullAddress`,
    defaultMessage: "FullAddress",
  },
  stationHeaderLatitude: {
    id: `${scope}.StationHeaderLatitude`,
    defaultMessage: "Latitude",
  },
  stationHeaderLongitude: {
    id: `${scope}.StationHeaderLongitude`,
    defaultMessage: "Longitude",
  },
  stationHeaderBrandName: {
    id: `${scope}.StationHeaderBrandName`,
    defaultMessage: "BrandName",
  },
  stationHeaderBrandCode: {
    id: `${scope}.StationHeaderBrandCode`,
    defaultMessage: "BrandCode",
  },
  stationHeaderVendorName: {
    id: `${scope}.StationHeaderVendorName`,
    defaultMessage: "Vendor.Name",
  },
  stationHeaderVendorStationId: {
    id: `${scope}.StationHeaderVendorStationId`,
    defaultMessage: "Vendor.VendorStationId",
  },
  stationHeaderVendorStationIds: {
    id: `${scope}.StationHeaderVendorStationIds`,
    defaultMessage: "Vendor.VendorStationIds",
  },
  stationHeaderVendorConfigurationName: {
    id: `${scope}.StationHeaderVendorConfigurationName`,
    defaultMessage: "Vendor.ConfigurationName",
  },
  stationHeaderVendorAdminPhoneNumber: {
    id: `${scope}.StationHeaderVendorAdminPhoneNumber`,
    defaultMessage: "Vendor.AdminPhoneNumber",
  },
  stationHeaderVendorOtpEnabled: {
    id: `${scope}.StationHeaderVendorOtpEnabled`,
    defaultMessage: "Vendor.OtpEnabled",
  },
  stationHeaderSupplierId: {
    id: `${scope}.StationHeaderSupplierId`,
    defaultMessage: "SupplierId",
  },
  stationHeaderCountryCode: {
    id: `${scope}.StationHeaderCountryCode`,
    defaultMessage: "CountryCode",
  },
  stationHeaderCurrency: {
    id: `${scope}.StationHeaderCurrency`,
    defaultMessage: "Currency",
  },
  stationHeaderCaic: {
    id: `${scope}.StationHeaderCaic`,
    defaultMessage: "Caic",
  },
  stationHeaderE100TerminalId: {
    id: `${scope}.StationHeaderE100TerminalId`,
    defaultMessage: "E100TerminalId",
  },
  stationHeaderTimeZone: {
    id: `${scope}.StationHeaderTimeZone`,
    defaultMessage: "TimeZone",
  },
  stationTestMode: {
    id: `${scope}.StationTestMode`,
    defaultMessage: "Test Mode",
  },
  stationHeaderRegion: {
    id: `${scope}.StationHeaderRegion`,
    defaultMessage: "Region",
  },
  totalCount: {
    id: `${scope}.TotalCount`,
    defaultMessage: "Total count: {count} item(s)",
  },
  downloadAll: {
    id: `${scope}.DownloadAll`,
    defaultMessage: "Export",
  },
  initialTitle: {
    id: `${scope}.InitialTitle`,
    defaultMessage: "Choose operation:",
  },
});
