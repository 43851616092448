import {
  SEARCH_CARDS,
  SEARCH_CARDS_FAILED,
  SEARCH_CARDS_SUCCEEDED,
} from "./constants";
import CardState from "./state";

const initialState: CardState = {
  loading: false,
  alreadySearched: false,
  cardNumber: undefined,
  cards: [],
};

const cardReducer = (
  state: CardState = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): CardState => {
  switch (action.type) {
    case SEARCH_CARDS:
      return {
        ...state,
        loading: true,
        cardNumber: action.cardNumber,
      };

    case SEARCH_CARDS_FAILED:
      return {
        ...state,
        loading: false,
        alreadySearched: true,
      };

    case SEARCH_CARDS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        alreadySearched: true,
        cards: action.cards,
      };
  }

  return state;
};

export default cardReducer;
