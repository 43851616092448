import { Descriptions, Typography } from "antd";
import React from "react";
import { JSONTree } from "react-json-tree";

import Amount from "../../../../components/Amount";
import CardNumber from "../../../../components/CardNumber";
import DescriptionsRow from "../../../../components/DescriptionsRow";
import FuelingProcessStatus from "../../../../components/FuelingProcessStatus";
import FuelProductName from "../../../../components/FuelProductName";
import Litres from "../../../../components/Litres";
import MomentItem from "../../../../components/MomentItem";
import intl from "../../../../utils/intl";
import messages from "./messages";
import FuelingProcessDetailBasicInfoProps from "./props";

const theme = {
  scheme: "monokai",
  author: "wimer hazenberg (http://www.monokai.nl)",
  base00: "#272822",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#f5f4f1",
  base07: "#f9f8f5",
  base08: "#f92672",
  base09: "#fd971f",
  base0A: "#f4bf75",
  base0B: "#a6e22e",
  base0C: "#a1efe4",
  base0D: "#66d9ef",
  base0E: "#ae81ff",
  base0F: "#cc6633",
};

const FuelingProcessDetailBasicInfo: React.FC<
  FuelingProcessDetailBasicInfoProps
> = (props: FuelingProcessDetailBasicInfoProps) => {
  return (
    <>
      <Typography.Title level={4}>
        {intl.formatMessage(messages.detailsTitle)}
      </Typography.Title>
      <Descriptions bordered={true} column={1} size="small">
        <Descriptions.Item label={intl.formatMessage(messages.processIdTitle)}>
          <span id="processId">{props.details.fuelingProcessId}</span>
        </Descriptions.Item>

        <Descriptions.Item label={intl.formatMessage(messages.stateTitle)}>
          <FuelingProcessStatus
            state={props.details.state}
            id="fuelingProcessStatus"
          />
        </Descriptions.Item>

        <Descriptions.Item label={intl.formatMessage(messages.cardNumberTitle)}>
          <CardNumber cardNumber={props.details.cardNumber} />
        </Descriptions.Item>

        <Descriptions.Item label={intl.formatMessage(messages.createTimeTitle)}>
          <MomentItem value={props.details.createTime} />
        </Descriptions.Item>

        <Descriptions.Item label={intl.formatMessage(messages.productIdTitle)}>
          <FuelProductName
            productId={props.details.productId}
            vendor={props.details.station.vendor}
          />
        </Descriptions.Item>

        <Descriptions.Item label={intl.formatMessage(messages.pumpNumberTitle)}>
          {props.details.pumpNumber}
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage(messages.requestedAmountTitle)}
        >
          <Amount value={props.details.requestedAmount} id="requestedAmount" />
        </Descriptions.Item>

        <Descriptions.Item label={intl.formatMessage(messages.stationTitle)}>
          <DescriptionsRow label={intl.formatMessage(messages.stationIdTitle)}>
            {props.details.station.stationId}
          </DescriptionsRow>
          <DescriptionsRow
            label={intl.formatMessage(messages.stationSupplierIdTitle)}
          >
            {props.details.station.supplierId}
          </DescriptionsRow>
          <DescriptionsRow
            label={intl.formatMessage(messages.stationNameTitle)}
          >
            {props.details.station.name}
          </DescriptionsRow>
          <DescriptionsRow
            label={intl.formatMessage(messages.stationVendorTitle)}
          >
            <JSONTree
              data={props.details.station.vendorConfiguration as object}
              theme={theme}
              hideRoot
              invertTheme
            />
          </DescriptionsRow>
          <DescriptionsRow
            label={intl.formatMessage(messages.stationBrandTitle)}
          >
            {props.details.station.brand.name}
          </DescriptionsRow>
        </Descriptions.Item>

        {props.details.payment && (
          <Descriptions.Item label={intl.formatMessage(messages.paymentTitle)}>
            <DescriptionsRow
              label={intl.formatMessage(messages.paymentAmountTitle)}
            >
              <Amount
                value={props.details.payment.authorizedAmount}
                id="authorizedAmount"
              />
            </DescriptionsRow>

            <DescriptionsRow
              label={intl.formatMessage(messages.paymentRrnTitle)}
            >
              {props.details.payment.rrn}
            </DescriptionsRow>
          </Descriptions.Item>
        )}

        {props.details.batch && (
          <DescriptionsRow label={intl.formatMessage(messages.batchTitle)}>
            {props.details.batch}
          </DescriptionsRow>
        )}

        {props.details.rejection && (
          <Descriptions.Item
            label={intl.formatMessage(messages.rejectionReasonTitle)}
          >
            <DescriptionsRow
              label={intl.formatMessage(messages.rejectionReasonCodeTitle)}
            >
              {props.details.rejection.code}
            </DescriptionsRow>

            <DescriptionsRow
              label={intl.formatMessage(
                messages.rejectionReasonDescriptionTitle
              )}
              visible={!!props.details.rejection.description}
            >
              {props.details.rejection.description}
            </DescriptionsRow>
          </Descriptions.Item>
        )}
        {props.details.receipt && (
          <Descriptions.Item label={intl.formatMessage(messages.receiptTitle)}>
            <DescriptionsRow
              label={intl.formatMessage(messages.receiptQuantityTitle)}
            >
              <Litres value={props.details.receipt.quantity} />
            </DescriptionsRow>
            <DescriptionsRow
              label={intl.formatMessage(messages.receiptAmountTitle)}
            >
              <Amount
                value={props.details.receipt.totalAmount}
                id="receiptAmount"
              />
            </DescriptionsRow>
            <DescriptionsRow
              label={intl.formatMessage(messages.receiptPriceTitle)}
            >
              {props.details.receipt.price && (
                <Amount value={props.details.receipt.price} id="receiptPrice" />
              )}
            </DescriptionsRow>
          </Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
};

export default FuelingProcessDetailBasicInfo;
