import "antd/dist/antd.css";

import { Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import AppContent from "../../components/AppContent";
import State from "../../State";
import intl from "../../utils/intl";
import * as actions from "./actions";
import FuelingProcessDetail from "./components/FuelingProcessDetail";
import FuelingProcessFilter from "./components/FuelingProcessFilter";
import { FilterValues } from "./components/FuelingProcessFilter/props";
import FuelingProcessTable from "./components/FuelingProcessTable";
import useQuery from "./hooks";
import messages from "./messages";
import FuelingProcessesPageProps from "./props";

const FuelingProcessLayout = styled(AppContent)`
  padding: 30px;
`;

const FuelingProcessWrapper = styled.div`
  padding-top: 20px;
`;

const FuelingProcessPage: React.FC = () => {
  const [readFromQuery, setReadFromQuery] = useState(false);
  const [drawerVisible, setVisibilityForDrawer] = useState(false);

  const dispatch = useDispatch();

  const { tableProps, detailProps } = useSelector<
    State,
    FuelingProcessesPageProps
  >((state) => {
    return {
      tableProps: {
        loading: state.processesPage.tableState.loading,
        processes: state.processesPage.tableState.processes.map((process) => {
          return {
            fuelingProcessId: process.fuelingProcessId,
            cardNumber: process.cardNumber,
            createTime: process.createTime,
            state: process.state,
            station: process.station.stationId,
            vendor: process.station.vendor,
            productId: process.productId,
            requestedAmount: process.requestedAmount,
            receiptPrice: process.receipt?.price,
            receiptAmount: process.receipt?.totalAmount,
            quantity: process.receipt?.quantity,
            rejectionReason: process.rejection?.code,
            rejectionDescription: process.rejection?.description,
            clientId: process.clientId,
            partnerId: process.partnerId,
            partnerRefuelingId: process.partnerRefuelingId,
            supplierId: process.station.supplierId,
            batch: process.batch,
            testMode: process.testMode,
            initialPrice: process.initialPrice,
          };
        }),
        filter: state.processesPage.tableState.filter,
        viewDetail: (fuelingProcessId: string): void => {
          dispatch(actions.loadFuelingProcessDetail(fuelingProcessId));
          setVisibilityForDrawer(true);
        },
        exportToExcel: (values: FilterValues): void => {
          dispatch(actions.exportFuelingProcessesToExcel(values));
        },
        exportingToExcel:
          state.processesPage.exportToExcelState.exportingToExcel,
        downloadUrl: state.processesPage.exportToExcelState.downloadUrl,
      },
      detailProps: {
        loading: state.processesPage.detailState.loading,
        fuelingProcess: state.processesPage.detailState.fuelingProcess,
      },
    };
  });

  const search = (values: FilterValues): void => {
    dispatch(
      actions.loadFuelingProcesses({
        cardNumber: values.cardNumber,
        dateTimeFrom: values.dateTimeFrom,
        dateTimeTill: values.dateTimeTill,
        stationId: values.stationId,
      })
    );
  };

  const closeDrawer = (): void => {
    setVisibilityForDrawer(false);
  };

  const query = useQuery();
  const cardNumberFromQuery = query.get("cardNumber");
  if (!readFromQuery && cardNumberFromQuery != null) {
    tableProps.filter = {
      cardNumber: cardNumberFromQuery,
    };
  } else if (
    tableProps.filter.cardNumber === undefined &&
    tableProps.filter.dateTimeFrom === undefined &&
    tableProps.filter.dateTimeTill === undefined &&
    tableProps.filter.stationId === undefined
  ) {
    tableProps.filter = {
      dateTimeFrom: moment(),
    };
  }

  useEffect(() => {
    if (!readFromQuery) {
      setReadFromQuery(true);
      if (cardNumberFromQuery != null) {
        dispatch(actions.loadFuelingProcesses(tableProps.filter));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.header)}</title>
      </Helmet>
      <FuelingProcessWrapper>
        <FuelingProcessLayout>
          <FuelingProcessFilter
            loading={tableProps.loading}
            search={search}
            filter={tableProps.filter}
            exportToExcel={tableProps.exportToExcel}
            exportingToExcel={tableProps.exportingToExcel}
            downloadUrl={tableProps.downloadUrl}
          />
        </FuelingProcessLayout>
      </FuelingProcessWrapper>

      <FuelingProcessWrapper>
        <FuelingProcessLayout>
          <FuelingProcessTable {...tableProps} />
          <Drawer
            width={640}
            placement="right"
            onClose={closeDrawer}
            visible={drawerVisible}
          >
            <FuelingProcessDetail
              loading={detailProps.loading}
              fuelingProcess={detailProps.fuelingProcess}
            />
          </Drawer>
        </FuelingProcessLayout>
      </FuelingProcessWrapper>
    </>
  );
};

export default FuelingProcessPage;
