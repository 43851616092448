import { defineMessages } from "react-intl";

const scope = "supportui.component.FuelingProcessBasicInfo";

export default defineMessages({
  cardNumberTitle: {
    id: `${scope}.cardNumberTitle`,
    defaultMessage: "Card Number",
  },
  createTimeTitle: {
    id: `${scope}.createTimeTitle`,
    defaultMessage: "Create Time, UTC",
  },
  stateTitle: {
    id: `${scope}.stateTitle`,
    defaultMessage: "State",
  },
  stationTitle: {
    id: `${scope}.stationTitle`,
    defaultMessage: "Station",
  },
  stationIdTitle: {
    id: `${scope}.stationIdTitle`,
    defaultMessage: "Id",
  },
  stationSupplierIdTitle: {
    id: `${scope}.stationSupplierIdTitle`,
    defaultMessage: "Supplier",
  },
  stationNameTitle: {
    id: `${scope}.stationNameTitle`,
    defaultMessage: "Name",
  },
  stationVendorTitle: {
    id: `${scope}.stationVendorTitle`,
    defaultMessage: "Vendor",
  },
  stationBrandTitle: {
    id: `${scope}.stationBrandTitle`,
    defaultMessage: "Brand",
  },
  productIdTitle: {
    id: `${scope}.productIdTitle`,
    defaultMessage: "Product",
  },
  requestedAmountTitle: {
    id: `${scope}.requestedAmountTitle`,
    defaultMessage: "Requested Amount",
  },
  rejectionReasonTitle: {
    id: `${scope}.rejectionReasonTitle`,
    defaultMessage: "Rejection Reason",
  },
  rejectionReasonCodeTitle: {
    id: `${scope}.rejectionReasonCodeTitle`,
    defaultMessage: "Code",
  },
  rejectionReasonDescriptionTitle: {
    id: `${scope}.rejectionReasonDescriptionTitle`,
    defaultMessage: "Description",
  },
  receiptTitle: {
    id: `${scope}.receiptTitle`,
    defaultMessage: "Receipt",
  },
  receiptQuantityTitle: {
    id: `${scope}.receiptQuantityTitle`,
    defaultMessage: "Quantity",
  },
  receiptAmountTitle: {
    id: `${scope}.receiptAmountTitle`,
    defaultMessage: "Amount",
  },
  receiptPriceTitle: {
    id: `${scope}.receiptPriceTitle`,
    defaultMessage: "Price",
  },
  paymentTitle: {
    id: `${scope}.paymentTitle`,
    defaultMessage: "E100 pre-authorization",
  },
  paymentAmountTitle: {
    id: `${scope}.paymentAmountTitle`,
    defaultMessage: "Amount",
  },
  paymentRrnTitle: {
    id: `${scope}.paymentRrnTitle`,
    defaultMessage: "Rrn",
  },
  pumpNumberTitle: {
    id: `${scope}.pumpNumberTitle`,
    defaultMessage: "Pump Number",
  },
  detailsTitle: {
    id: `${scope}.detailsTitle`,
    defaultMessage: "Fueling process information",
  },
  processIdTitle: {
    id: `${scope}.processIdTitle`,
    defaultMessage: "ProcessId",
  },
  batchTitle: {
    id: `${scope}.batchTitle`,
    defaultMessage: "Batch",
  },
});
