import { Agreement } from "./Agreement";
import {
  AGREEMENT_DELETE_FAILED,
  AGREEMENT_DELETE_SUCCESS,
  AGREEMENTS_EXPORTED,
  AGREEMENTS_LOADED,
  CREATE_AGREEMENT,
  CREATE_AGREEMENT_FAILED,
  CREATE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT,
  EXPORT_AGREEMENTS,
  HIDE_CREATE_AGREEMENT_DIALOG,
  LOAD_AGREEMENTS,
  SHOW_CREATE_AGREEMENT_DIALOG,
} from "./constants";
import AgreementsPageState from "./state";

const tableInitialState: AgreementsPageState = {
  loading: false,
  loaded: false,
  agreements: Array<Agreement>(),
  createDialog: {
    visible: false,
    creating: false,
  },
  deleting: {
    agreement: undefined,
  },
  exporting: false,
};

const agreementTableReducer = (
  state: AgreementsPageState = tableInitialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): AgreementsPageState => {
  switch (action.type) {
    case LOAD_AGREEMENTS:
      return {
        ...state,
        loading: true,
      };
    case AGREEMENTS_LOADED:
      return {
        ...state,
        loading: false,
        loaded: true,
        agreements: action.agreements.sort(Agreement.defaultSort),
      };
    case SHOW_CREATE_AGREEMENT_DIALOG:
      return {
        ...state,
        createDialog: { ...state.createDialog, visible: true },
      };
    case HIDE_CREATE_AGREEMENT_DIALOG:
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          visible: false,
          creating: false,
        },
      };
    case CREATE_AGREEMENT:
      return {
        ...state,
        createDialog: { ...state.createDialog, creating: true },
      };
    case CREATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreements: [...state.agreements, action.payload].sort(
          Agreement.defaultSort
        ),
        createDialog: {
          ...state.createDialog,
          creating: false,
          visible: false,
        },
      };
    case CREATE_AGREEMENT_FAILED:
      return {
        ...state,
        createDialog: { ...state.createDialog, creating: false },
      };
    case DELETE_AGREEMENT:
      return {
        ...state,
        deleting: {
          agreement: action.payload,
        },
      };
    case AGREEMENT_DELETE_FAILED:
      return {
        ...state,
        deleting: {
          agreement: undefined,
        },
      };
    case AGREEMENT_DELETE_SUCCESS: {
      return {
        ...state,
        agreements: state.agreements.filter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (agreement) => !agreement.equals(state.deleting.agreement!)
        ),
        deleting: {
          agreement: undefined,
        },
      };
    }
    case EXPORT_AGREEMENTS: {
      return {
        ...state,
        exporting: true,
      };
    }
    case AGREEMENTS_EXPORTED: {
      return {
        ...state,
        exporting: false,
      };
    }
    default:
      return state;
  }
};

export default agreementTableReducer;
