import React from "react";
import { FormattedNumber } from "react-intl";

import AmountProps from "./props";

const Amount: React.FC<AmountProps> = (props: AmountProps) => {
  return (
    <span id={props.id}>
      <FormattedNumber
        value={props.value.amount}
        currency={props.value.currency}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
      ></FormattedNumber>
    </span>
  );
};

export default Amount;
