import { defineMessages } from "react-intl";

const scope = "supportui.containers.FuelingProcessesPage";

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Fueling processes",
  },
  exportProcessesToExcelTimeout: {
    id: `${scope}.exportProcessesToExcelTimeout`,
    defaultMessage:
      "Exporting fueling processes to Excel is taking longer than expected. Please try again later.",
  },
});
