import { defineMessages } from "react-intl";

const scope = "supportui.containers.FuelingProcessState";

export default defineMessages({
  created: {
    id: `${scope}.created`,
    defaultMessage: "Created",
  },
  paymentPreAuthorized: {
    id: `${scope}.paymentPreAuthorized`,
    defaultMessage: "Payment pre-authorized",
  },
  pumpReady: {
    id: `${scope}.pumpReady`,
    defaultMessage: "Pump ready",
  },
  tankingStarted: {
    id: `${scope}.tankingStarted`,
    defaultMessage: "Tanking started",
  },
  receiptReceived: {
    id: `${scope}.receiptReceived`,
    defaultMessage: "Receipt received",
  },
  rejected: {
    id: `${scope}.rejected`,
    defaultMessage: "Rejected",
  },
  paymentConfirmed: {
    id: `${scope}.paymentConfirmed`,
    defaultMessage: "Payment confirmed",
  },
  paymentCancelled: {
    id: `${scope}.paymentCancelled`,
    defaultMessage: "Payment cancelled",
  },
  failed: {
    id: `${scope}.failed`,
    defaultMessage: "Failed",
  },
});
