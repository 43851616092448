import React from "react";

import CardNumberProps from "./props";

export default function CardNumber(props: CardNumberProps): JSX.Element {
  const cardNumber = props.cardNumber;

  if (cardNumber.length !== 19) {
    return <>{cardNumber}</>;
  }

  const formattedCardNumber = cardNumber.replace(
    /(\w{6})(\w{4})(\w{9})/,
    "$1-$2-$3"
  );

  return <>{formattedCardNumber}</>;
}
