import { SET_LOGIN_SESSION, SET_SESSION_EXPIRED } from "./constants";
import LoginSessionState from "./state";

// for local develop
//
// const initialState: LoginSessionState = {
//   isLoggedIn: true,
//   session: {
//     credentials: {
//       accessToken: "",
//       idToken: "",
//       refreshToken: "",
//     },
//     user: {
//       userName: "",
//       email: "",
//     },
//   },
// };

const initialState: LoginSessionState = {
  isLoggedIn: false,
};

const sessionLoginReducer = (
  state: LoginSessionState = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): LoginSessionState => {
  switch (action.type) {
    case SET_LOGIN_SESSION:
      return {
        ...state,
        session: action.session,
        isLoggedIn: true,
      };
    case SET_SESSION_EXPIRED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default sessionLoginReducer;
