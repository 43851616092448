import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import AppContent from "../../components/AppContent";
import { RightIndentWrapper } from "../../spaces";
import State from "../../State";
import intl from "../../utils/intl";
import * as actions from "./actions";
import { Agreement } from "./Agreement";
import AgreementTable from "./components/AgreementTable";
import AgreementTableProps from "./components/AgreementTable/props";
import CreateAgreementDialog from "./components/CreateAgreementDialog";
import CreateButton from "./components/CreateButton";
import messages from "./messages";
import AgreementsPageProps from "./props";
import { CreateAgreementDialogState } from "./state";

const AgreementsWrapper = styled.div`
  padding-top: 20px;
`;

const AgreementsLayout = styled(AppContent)`
  padding: 30px;
`;

const AgreementsPage: React.FC = () => {
  const dispatch = useDispatch();

  const pageProps = useSelector<State, AgreementsPageProps>((state) => {
    return {
      loaded: state.agreements.loaded,
      loading: state.agreements.loading,
      agreements: state.agreements.agreements,
      deletingAgreement: state.agreements.deleting.agreement,
      exporting: state.agreements.exporting,
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  });

  const showDialog = () => {
    dispatch(actions.showCreateAgreementDialog());
  };

  const hideDialog = () => {
    dispatch(actions.hideCreateAgreementDialog());
  };

  const createAgreement = (agreement: Agreement) => {
    dispatch(actions.createAgreement(agreement));
  };

  const exportAgreements = (): void => {
    dispatch(actions.exportAgreements());
  };

  const createDialogState = useSelector<State, CreateAgreementDialogState>(
    (state) => state.agreements.createDialog
  );

  useEffect(() => {
    if (pageProps.loaded || pageProps.loading) {
      return;
    }

    dispatch(actions.loadAgreements());
  }, [pageProps.loaded, pageProps.loading, dispatch]);

  const tableProps: AgreementTableProps = {
    loading: pageProps.loading,
    agreements: pageProps.agreements,
    delete: (agreement: Agreement): void => {
      dispatch(actions.deleteAgreement(agreement));
    },
    deletingAgreement: pageProps.deletingAgreement,
    exporting: pageProps.exporting,
  };

  return (
    <>
      <AgreementsWrapper>
        <AgreementsLayout>
          <RightIndentWrapper>
            <CreateButton onClick={showDialog} />
          </RightIndentWrapper>
          <Button
            id="downloadAllButton"
            type="primary"
            onClick={exportAgreements}
            loading={tableProps.exporting}
            icon={<DownloadOutlined />}
          >
            {intl.formatMessage(messages.exportAgreements)}
          </Button>
          <AgreementTable {...tableProps} />
        </AgreementsLayout>
      </AgreementsWrapper>

      <CreateAgreementDialog
        visible={createDialogState.visible}
        creating={createDialogState.creating}
        create={createAgreement}
        cancel={hideDialog}
      />
    </>
  );
};

export default AgreementsPage;
