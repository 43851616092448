import { CreditCardOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import Form from "antd/lib/form";
import React from "react";
import styled from "styled-components";

import intl from "../../utils/intl";
import messages from "./messages";
import CardSearchProps from "./props";

const CardNumberInput = styled(Input)`
  width: 230px;
`;

const CardSearch: React.FC<CardSearchProps> = (props: CardSearchProps) => {
  const initialValues = {
    cardNumber: props.cardNumber,
  };

  const search = (values: { cardNumber?: string }): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cardNumberDigits = values.cardNumber!.replace(/\D/g, "");
    props.search(cardNumberDigits);
  };

  return (
    <Form onFinish={search} initialValues={initialValues}>
      <Row gutter={24}>
        <Col>
          <Form.Item
            label={intl.formatMessage(messages.cardNumberLabel)}
            name="cardNumber"
            rules={[
              {
                required: true,
                message: intl.formatMessage(messages.inputCardNumberMessage),
                validator: (_, value): Promise<string | void> => {
                  if (
                    !new RegExp(/^([0-9]|\s|-)+$/).test(value) ||
                    value.replace(/\D/g, "").length < 5
                  ) {
                    return Promise.reject(
                      intl.formatMessage(messages.inputCardNumberMessage)
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <CardNumberInput
              id="card-number-search-input"
              allowClear
              prefix={<CreditCardOutlined />}
              placeholder={intl.formatMessage(messages.cardNumberPlaceholder)}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button
            id="card-number-search-submit"
            type="primary"
            htmlType="submit"
            loading={props.loading}
          >
            {intl.formatMessage(messages.searchButtonText)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CardSearch;
