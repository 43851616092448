import { AxiosResponse } from "axios";

export default function downloadFromBlob(
  response: AxiosResponse<Blob, unknown>
): void {
  const blob = response.data;

  const contentDisposition = response.headers["content-disposition"];
  let filename = "file";
  if (contentDisposition) {
    const match = contentDisposition.match(/filename="(.+)"/);
    if (match && match[1]) {
      filename = match[1];
    }
  }

  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
