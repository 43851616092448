import React from "react";

import { ExternalRouteProps } from "./props";

const ExternalRedirectRoute: React.FC<ExternalRouteProps> = (
  props: ExternalRouteProps
) => {
  React.useEffect(() => {
    window.location.replace(props.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};
export default ExternalRedirectRoute;
