import React from "react";

import { getProductName } from "./productIdToProductNameDictionary";
import FuelProductNameProps from "./props";

const fuelProductName: React.FC<FuelProductNameProps> = (
  props: FuelProductNameProps
) => {
  const name = getProductName(props.vendor, props.productId);
  return <>{name}</>;
};

export default fuelProductName;
