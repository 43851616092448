import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { put, select, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";

import { callApi } from "../../sagas";
import State from "../../State";
import { Cognito } from "../../utils/cognito";
import errors from "../../utils/errors";
import intl from "../../utils/intl";
import startDownload from "../../utils/startDownload";
import { setError } from "../App/actions";
import * as actions from "./actions";
import { Agreement } from "./Agreement";
import {
  CREATE_AGREEMENT,
  DELETE_AGREEMENT,
  EXPORT_AGREEMENTS,
  LOAD_AGREEMENTS,
} from "./constants";
import messages from "./messages";

const E100SupplierPartnerId = "E100";

const getAccessToken = (state: State): string =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  state.session.session!.credentials.accessToken;

function* loadAgreements(axiosClient: AxiosInstance, cognito: Cognito) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response: AxiosResponse = yield callApi(
      cognito,
      accessToken,
      axiosClient.get,
      "/admin/agreements",
      requestConfig
    );

    yield put(
      actions.agreementsLoaded(
        response.data.map(
          (agreement: { cardIssuerId: string; supplierId: string }) =>
            new Agreement(agreement.cardIssuerId, agreement.supplierId)
        )
      )
    );
  } catch (error) {
    yield put(setError(error));
  }
}

export function* watchLoadAgreements(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  yield takeLatest(LOAD_AGREEMENTS, loadAgreements, axiosClient, cognito);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* createAgreementSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.CreateAgreementAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    yield callApi(
      cognito,
      accessToken,
      axiosClient.post,
      "/admin/agreements",
      {
        cardIssuerId: action.agreement.cardIssuerId,
        supplierId: action.agreement.supplierId,
        supplierPartnerId: E100SupplierPartnerId,
      },
      requestConfig
    );

    yield put(actions.createAgreementSuccess(action.agreement));
  } catch (e) {
    yield put(
      setError(
        `${intl.formatMessage(
          messages.unableToCreateAgreement
        )} ${errorToString(e)}`
      )
    );
    yield put(actions.createAgreementFailed());
  }
}

function errorToString(e: unknown): string {
  if (e instanceof AxiosError && e.response && e.response.status === 409) {
    return intl.formatMessage(messages.agreementConflict);
  } else {
    return errors.toString(e);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* watchCreateAgreement(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeLatest(
      CREATE_AGREEMENT,
      createAgreementSaga,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}

export function* deleteAgreement(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.DeleteAgreementAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const data = {
      cardIssuerId: action.payload.cardIssuerId,
      supplierId: action.payload.supplierId,
      supplierPartnerId: E100SupplierPartnerId,
    };

    yield callApi(
      cognito,
      accessToken,
      axiosClient.post,
      "/admin/agreements/delete",
      data,
      requestConfig
    );

    yield put(actions.agreementDeleteSuccess());
  } catch (error) {
    yield put(actions.agreementDeleteFailed());
    yield put(setError(error));
  }
}

interface CreateDownloadFileResponse {
  downloadUrl: string;
}

export function* exportAgreements(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const response: AxiosResponse<CreateDownloadFileResponse> = yield callApi(
      cognito,
      accessToken,
      axiosClient.post,
      "/admin/agreements/export",
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    yield call(startDownload, response.data.downloadUrl);

    yield put(actions.agreementsExported());
  } catch (e) {
    const message = errors.toString(e);
    yield put(setError(`unable to export agreements: ${message}`));
  }
}

export function* watchDeleteAgreement(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeLatest(DELETE_AGREEMENT, deleteAgreement, axiosClient, cognito);
  } catch (error) {
    yield put(setError(error));
  }
}
export function* watchExportAgreements(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeLatest(EXPORT_AGREEMENTS, exportAgreements, axiosClient, cognito);
  } catch (error) {
    yield put(setError(error));
  }
}
