import { ErrorAction } from "./actions";
import { SET_ERROR } from "./constants";
import ErrorState from "./state";

const initialState: ErrorState = {
  lastErrorMessage: "",
};

const errorReducer = (
  state: ErrorState = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: ErrorAction
): ErrorState => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        lastErrorMessage: action.message,
      };
    default:
      return state;
  }
};

export default errorReducer;
