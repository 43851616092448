import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import cognito from "../../utils/cognito";
import config from "../../utils/config";
import ExternalRedirectRoute from "./ExternalRedirectRoute";
import { ProtectedOutletProps } from "./props";

const ProtectedOutlet: React.FC<ProtectedOutletProps> = (
  props: ProtectedOutletProps
) => {
  const externalUrl = cognito.getCognitoSignInUri(config.cognito);
  const location = useLocation();
  const needRedirect = !props.isLoggedIn && location.pathname !== "/callback";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return needRedirect ? (
    <ExternalRedirectRoute link={externalUrl} path={props.path} />
  ) : (
    <Outlet />
  );
};

export default ProtectedOutlet;
