// eslint-disable-next-line @typescript-eslint/no-empty-interface
import { Vendor } from "../../model/vendor";
import {
  ACTION_COMPLETE_STATIONS_UPLOAD,
  ACTION_DOWNLOAD_STATIONS,
  ACTION_DOWNLOAD_STATIONS_STARTED,
  ACTION_EXPORT_VENDOR_STATIONS,
  ACTION_EXPORT_VENDOR_STATIONS_FAILED,
  ACTION_SAVE_STATIONS,
  ACTION_STATIONS_SAVED,
  ACTION_UPLOAD_STATIONS,
  ACTION_UPLOAD_STATIONS_FAILED,
  ACTION_UPLOAD_STATIONS_SUCCEEDED,
  ACTION_VENDOR_STATIONS_DOWNLOADED,
} from "./constants";
import { Station } from "./Station";

export interface UploadStationsAction {
  type: typeof ACTION_UPLOAD_STATIONS;
  content: File;
}

export interface StationUploadSucceededAction {
  type: typeof ACTION_UPLOAD_STATIONS_SUCCEEDED;
  stations: Array<Station>;
  stationImportId: string;
}

export interface StationUploadFailedAction {
  type: typeof ACTION_UPLOAD_STATIONS_FAILED;
  errors: Array<string>;
}

export interface CompleteStationsUploadAction {
  type: typeof ACTION_COMPLETE_STATIONS_UPLOAD;
}

export interface SaveStationsAction {
  type: typeof ACTION_SAVE_STATIONS;
  stationImportId: string;
}

export interface StationsSavedAction {
  type: typeof ACTION_STATIONS_SAVED;
}

export interface DownloadStationsAction {
  type: typeof ACTION_DOWNLOAD_STATIONS;
}

export interface DownloadStationsStartedAction {
  type: typeof ACTION_DOWNLOAD_STATIONS_STARTED;
}

export interface ExportVendorStationsAction {
  type: typeof ACTION_EXPORT_VENDOR_STATIONS;
  vendor: Vendor;
  vendorConfigurationName: string;
}

export interface VendorStationsDownloadedAction {
  type: typeof ACTION_VENDOR_STATIONS_DOWNLOADED;
}

export interface ExportVendorStationsFailedAction {
  type: typeof ACTION_EXPORT_VENDOR_STATIONS_FAILED;
}

export function uploadStations(content: File): UploadStationsAction {
  return {
    type: ACTION_UPLOAD_STATIONS,
    content: content,
  };
}

export function stationsUploadSucceeded(
  stations: Array<Station>,
  stationImportId: string
): StationUploadSucceededAction {
  return {
    type: ACTION_UPLOAD_STATIONS_SUCCEEDED,
    stations: stations,
    stationImportId: stationImportId,
  };
}

export function stationsUploadFailed(
  errors: Array<string>
): StationUploadFailedAction {
  return {
    type: ACTION_UPLOAD_STATIONS_FAILED,
    errors: errors,
  };
}

export function saveStations(stationImportId: string): SaveStationsAction {
  return {
    type: ACTION_SAVE_STATIONS,
    stationImportId: stationImportId,
  };
}

export function completeStationsUpload(): CompleteStationsUploadAction {
  return {
    type: ACTION_COMPLETE_STATIONS_UPLOAD,
  };
}

export function stationsSaved(): StationsSavedAction {
  return {
    type: ACTION_STATIONS_SAVED,
  };
}

export function downloadStations(): DownloadStationsAction {
  return {
    type: ACTION_DOWNLOAD_STATIONS,
  };
}

export function downloadStationsStarted(): DownloadStationsStartedAction {
  return {
    type: ACTION_DOWNLOAD_STATIONS_STARTED,
  };
}

export function exportVendorStations(
  vendor: Vendor,
  vendorConfigurationName: string
): ExportVendorStationsAction {
  return {
    type: ACTION_EXPORT_VENDOR_STATIONS,
    vendor: vendor,
    vendorConfigurationName: vendorConfigurationName,
  };
}

export function vendorStationsDownloaded(): VendorStationsDownloadedAction {
  return {
    type: ACTION_VENDOR_STATIONS_DOWNLOADED,
  };
}

export function vendorStationsExportFailed(): ExportVendorStationsFailedAction {
  return {
    type: ACTION_EXPORT_VENDOR_STATIONS_FAILED,
  };
}
