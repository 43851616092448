import { defineMessages } from "react-intl";

const scope = "supportui.components.Card";

export default defineMessages({
  cardNumberTitle: {
    id: `${scope}.cardNumberTitle`,
    defaultMessage: "Card number",
  },
  statusTitle: {
    id: `${scope}.statusTitle`,
    defaultMessage: "E100 Neo Status",
  },
  phoneNumberTitle: {
    id: `${scope}.phoneNumberTitle`,
    defaultMessage: "Phone number",
  },
  showFuelingProcesses: {
    id: `${scope}.showFuelingProcesses`,
    defaultMessage: "Show fueling processes",
  },
  activeCard: {
    id: `${scope}.activeCard`,
    defaultMessage: "Active",
  },
  inactiveCard: {
    id: `${scope}.inactiveCard`,
    defaultMessage: "Inactive",
  },
});
