import { createIntl, createIntlCache } from "react-intl";

const cache = createIntlCache();
const intl = createIntl(
  {
    locale: "en",
    messages: {},
  },
  cache
);

export default intl;
