import { defineMessages } from "react-intl";

const scope = "supportui.containers.AgreementTable";

export default defineMessages({
  cardIssuerIdTitle: {
    id: `${scope}.cardIssuerIdTitle`,
    defaultMessage: "Card Issuer",
  },
  supplierIdTitle: {
    id: `${scope}.supplierIdTitle`,
    defaultMessage: "Supplier",
  },
  directAgreementTitle: {
    id: `${scope}.directAgreementTitle`,
    defaultMessage: "Direct",
  },
  actionTitle: {
    id: `${scope}.actionTitle`,
    defaultMessage: "Action",
  },
});
