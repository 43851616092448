import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import { setSessionExpired } from "./containers/SessionLogin/actions";
import { Cognito } from "./utils/cognito";

/* eslint-disable redux-saga/no-unhandled-errors, @typescript-eslint/no-explicit-any */
export function* callApi<Fn extends (...args: any[]) => any>(
  cognito: Cognito,
  accessToken: string,
  fn: Fn,
  ...args: Parameters<Fn>
) {
  const valid = cognito.isValidAccessToken(accessToken);
  if (!valid) {
    yield put(setSessionExpired());
  } else {
    const response: AxiosResponse<any> = yield call(fn, ...args);
    return response;
  }
}
/* eslint-enable redux-saga/no-unhandled-errors, @typescript-eslint/no-explicit-any */
