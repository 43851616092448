import React from "react";
import { FormattedNumber } from "react-intl";

import LitresProps from "./props";

const Litres: React.FC<LitresProps> = (props: LitresProps) => {
  return (
    <FormattedNumber
      value={props.value}
      minimumFractionDigits={2}
      maximumFractionDigits={2}
      unit="liter"
      // eslint-disable-next-line react/style-prop-object
      style="unit"
    ></FormattedNumber>
  );
};

export default Litres;
