import {
  EXPORT_FUELING_PROCESSES_TO_EXCEL,
  EXPORT_FUELING_PROCESSES_TO_EXCEL_FAILED,
  EXPORT_FUELING_PROCESSES_TO_EXCEL_SUCCESS,
  LOAD_FUELING_PROCESS_DETAILS,
  LOAD_FUELING_PROCESS_DETAILS_FAILED,
  LOAD_FUELING_PROCESS_DETAILS_SUCCESS,
  LOAD_FUELING_PROCESSES,
  LOAD_FUELING_PROCESSES_FAILED,
  LOAD_FUELING_PROCESSES_SUCCESS,
} from "./constants";
import {
  ExportToExcelState,
  FuelingProcessDetail,
  FuelingProcessesDetailState,
  FuelingProcessTableState,
} from "./state";

const tableInitialState: FuelingProcessTableState = {
  loading: false,
  processes: Array<FuelingProcessDetail>(),
  filter: {},
};

const detailInitialState: FuelingProcessesDetailState = {
  loading: false,
};

export const fuelingProcessesTableReducer = (
  state: FuelingProcessTableState = tableInitialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): FuelingProcessTableState => {
  switch (action.type) {
    case LOAD_FUELING_PROCESSES:
      return {
        ...state,
        loading: true,
        filter: action.filter,
      };

    case LOAD_FUELING_PROCESSES_FAILED:
      return {
        ...state,
        loading: false,
      };

    case LOAD_FUELING_PROCESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        processes: action.fuelingProcesses,
      };
  }

  return state;
};

export const fuelingProcessesDetailsReducer = (
  state: FuelingProcessesDetailState = detailInitialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): FuelingProcessesDetailState => {
  switch (action.type) {
    case LOAD_FUELING_PROCESS_DETAILS:
      return {
        loading: true,
      };

    case LOAD_FUELING_PROCESS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        fuelingProcess: action.fuelingProcess,
      };

    case LOAD_FUELING_PROCESS_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      };
  }

  return state;
};

export const exportToExcelReducer = (
  state: ExportToExcelState = { exportingToExcel: false },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): ExportToExcelState => {
  switch (action.type) {
    case EXPORT_FUELING_PROCESSES_TO_EXCEL:
      return {
        ...state,
        exportingToExcel: true,
      };

    case EXPORT_FUELING_PROCESSES_TO_EXCEL_FAILED:
      return {
        ...state,
        exportingToExcel: false,
      };

    case EXPORT_FUELING_PROCESSES_TO_EXCEL_SUCCESS:
      return {
        ...state,
        exportingToExcel: false,
        downloadUrl: action.downloadUrl,
      };

    default:
      return state;
  }
};
