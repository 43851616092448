// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toString = (err: any): string => {
  if (err instanceof Error) {
    return err.message;
  } else if (typeof err === "string") {
    return err;
  } else {
    return JSON.stringify(err);
  }
};

export default { toString };
