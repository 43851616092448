import { Table } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";

import Amount from "../../../../components/Amount";
import CardNumber from "../../../../components/CardNumber";
import FuelingProcessStatus from "../../../../components/FuelingProcessStatus";
import FuelProductName from "../../../../components/FuelProductName";
import Litres from "../../../../components/Litres";
import MomentItem from "../../../../components/MomentItem";
import RejectionReason from "../../../../components/RejectionReason";
import intl from "../../../../utils/intl";
import FuelingProcessTableItem from "./FuelingProcessTableItem";
import messages from "./messages";
import FuelingProcessTableProps from "./props";

const createTimeSorter = (
  a: FuelingProcessTableItem,
  b: FuelingProcessTableItem
): number => a.createTime.unix() - b.createTime.unix();

const FuelingProcessTable: React.FC<FuelingProcessTableProps> = (
  props: FuelingProcessTableProps
) => {
  return (
    <>
      <Table
        dataSource={props.processes}
        rowKey="fuelingProcessId"
        scroll={{ x: "max-content" }}
      >
        <Column
          title={intl.formatMessage(messages.cardNumberTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode => (
            <CardNumber cardNumber={record.cardNumber} />
          )}
        />
        <Column
          title={intl.formatMessage(messages.createTimeTitle)}
          sorter={createTimeSorter}
          defaultSortOrder="descend"
          render={(record: FuelingProcessTableItem): React.ReactNode => (
            <MomentItem value={record.createTime} />
          )}
        />
        <Column
          title={intl.formatMessage(messages.stateTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode => (
            <FuelingProcessStatus
              state={record.state}
              id={`process-state-${record.fuelingProcessId}`}
            />
          )}
        />
        <Column
          title={intl.formatMessage(messages.stationIdTitle)}
          dataIndex="station"
        />
        <Column
          title={intl.formatMessage(messages.supplierIdTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.supplierId
          }
        />
        <Column
          title={intl.formatMessage(messages.productIdTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode => (
            <FuelProductName
              productId={record.productId}
              vendor={record.vendor}
            />
          )}
        />
        <Column
          align="right"
          title={intl.formatMessage(messages.receiptPriceTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.receiptPrice && (
              <Amount
                value={record.receiptPrice}
                id={`rctprice-${record.fuelingProcessId}`}
              />
            )
          }
        />
        <Column
          align="right"
          title={intl.formatMessage(messages.requestedAmountTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.requestedAmount && (
              <Amount
                value={record.requestedAmount}
                id={`reqamt-${record.fuelingProcessId}`}
              />
            )
          }
        />
        <Column
          align="right"
          title={intl.formatMessage(messages.receiptAmountTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.receiptAmount && (
              <Amount
                value={record.receiptAmount}
                id={`rctamt-${record.fuelingProcessId}`}
              />
            )
          }
        />
        <Column
          align="right"
          title={intl.formatMessage(messages.receiptQuantityTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.quantity && <Litres value={record.quantity} />
          }
        />
        <Column
          title={intl.formatMessage(messages.rejectionReasonTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.rejectionReason && (
              <RejectionReason
                code={record.rejectionReason}
                id={`rejection-reason-${record.fuelingProcessId}`}
              />
            )
          }
        />
        <Column
          title={intl.formatMessage(messages.clientIdTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.clientId
          }
        />
        <Column
          title={intl.formatMessage(messages.partnerIdTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.partnerId
          }
        />
        <Column
          title={intl.formatMessage(messages.partnerRefuelingIdTitle)}
          render={(record: FuelingProcessTableItem): React.ReactNode =>
            record.partnerRefuelingId
          }
        />
        <Column
          title={intl.formatMessage(messages.actionTitle)}
          fixed={"right"}
          render={(record: FuelingProcessTableItem): React.ReactNode => {
            /* eslint-disable-next-line*/
              return (<a onClick={() => props.viewDetail(record.fuelingProcessId)} id={`details-${record.fuelingProcessId}`}>
                {intl.formatMessage(messages.viewDetails)}
              </a>
            );
          }}
        />
      </Table>
    </>
  );
};

export default FuelingProcessTable;
