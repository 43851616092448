import { defineMessages } from "react-intl";

const scope = "supportui.containers.ActionsCell";

export default defineMessages({
  deleteConfirmationTitle: {
    id: `${scope}.deleteConfirmationTitle`,
    defaultMessage: "Are you sure to delete this agreement?",
  },
  deleteConfirmationYesTitle: {
    id: `${scope}.deleteConfirmationOkTitle`,
    defaultMessage: "Yes",
  },
  deleteConfirmationNoTitle: {
    id: `${scope}.deleteConfirmationNoTitle`,
    defaultMessage: "No",
  },
});
