import { CongnitoSession } from "../../utils/cognito";
import {
  LOAD_SESSION,
  SET_LOGIN_SESSION,
  SET_SESSION_EXPIRED,
} from "./constants";

export interface LoadSessionAction {
  url: string;
  type: string;
}

export const loadSession = (href: string): LoadSessionAction => ({
  url: href,
  type: LOAD_SESSION,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setSession = (session: CongnitoSession) => ({
  session,
  type: SET_LOGIN_SESSION,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setSessionExpired = () => ({
  type: SET_SESSION_EXPIRED,
});
