import { call, put, takeLatest } from "redux-saga/effects";

import { CongnitoSession } from "../../utils/cognito";
import cognito from "../../utils/cognito";
import { setError } from "../App/actions";
import { LoadSessionAction, setSession } from "./actions";
import { LOAD_SESSION } from "./constants";

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
function* loadSessionSaga(action: LoadSessionAction) {
  try {
    yield call(cognito.parseCognitoWebResponse, action.url);
    const cognitoSession: CongnitoSession = yield call(
      cognito.getCognitoSession
    );
    yield put(setSession(cognitoSession));
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* watchLoadSessionProcesses() {
  try {
    yield takeLatest(LOAD_SESSION, loadSessionSaga);
  } catch (error) {
    yield put(setError(error));
  }
}
