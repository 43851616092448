import { Collapse, Timeline, Typography } from "antd";
import React from "react";
import { JSONTree } from "react-json-tree";
import styled from "styled-components";

import MomentItem from "../../../../components/MomentItem";
import intl from "../../../../utils/intl";
import messages from "./messages";
import FuelingProcessDetailEventsProps from "./props";

const Title = styled(Typography.Title)`
  padding: 0 0 10px;
`;

const theme = {
  scheme: "monokai",
  author: "wimer hazenberg (http://www.monokai.nl)",
  base00: "#272822",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#f5f4f1",
  base07: "#f9f8f5",
  base08: "#f92672",
  base09: "#fd971f",
  base0A: "#f4bf75",
  base0B: "#a6e22e",
  base0C: "#a1efe4",
  base0D: "#66d9ef",
  base0E: "#ae81ff",
  base0F: "#cc6633",
};

const FuelingProcessDetailEvents: React.FC<FuelingProcessDetailEventsProps> = (
  props: FuelingProcessDetailEventsProps
) => {
  const events = props.events.sort((a, b) => {
    return a.version - b.version;
  });

  return (
    <>
      <Title level={4}> {intl.formatMessage(messages.logsTitle)}</Title>

      <Timeline>
        {events.map((element) => {
          return (
            <Timeline.Item key={element.version}>
              <Collapse
                accordion={true}
                bordered={false}
                destroyInactivePanel={true}
              >
                <Collapse.Panel
                  header={
                    <>
                      <div>{element.type} </div>
                      <MomentItem value={element.timestamp} />
                    </>
                  }
                  key={element.version}
                >
                  <JSONTree
                    data={element.payload as object}
                    theme={theme}
                    hideRoot
                    invertTheme
                  />
                </Collapse.Panel>
              </Collapse>
              <span hidden id={`event-${element.version}`} />
            </Timeline.Item>
          );
        })}
      </Timeline>
    </>
  );
};

export default FuelingProcessDetailEvents;
