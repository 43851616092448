import { DownloadOutlined } from "@ant-design/icons";
import { Button, Input, Select, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

import { Vendor } from "../../model/vendor";
import intl from "../../utils/intl";
import messages from "./messages";
import VendorStationsExportProps from "./props";

const { Option } = Select;

const ButtonWrapper = styled.div`
  margin-top: 15px;
`;

const VendorStationsExport: React.FC<VendorStationsExportProps> = (
  props: VendorStationsExportProps
) => {
  const [vendor, setVendor] = useState(
    props.vendor || Vendor.Way4 || Vendor.Atan
  );
  const [vendorConfigurationName, setVendorConfigurationName] = useState(
    props.vendorConfigurationName || ""
  );

  const vendorOptions = [Vendor.Way4, Vendor.SupplierApi, Vendor.Atan];

  const onClickDownload = (): void => {
    props.export(vendor, vendorConfigurationName);
  };

  const onSelectChange = (value: Vendor) => {
    setVendor(value);

    if (value !== Vendor.SupplierApi) {
      setVendorConfigurationName("");
    }
  };

  return (
    <>
      <Typography.Title level={5}>
        {intl.formatMessage(messages.getVendorStations)}
      </Typography.Title>

      <div>
        <Select
          data-testid="vendorSelect"
          placeholder={intl.formatMessage(messages.vendor)}
          value={vendor}
          onChange={(value) => onSelectChange(value)}
          style={{ width: 200, marginRight: 10 }}
        >
          {vendorOptions.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>

        {vendor === Vendor.SupplierApi && (
          <Input
            data-testid="vendorConfigurationNameInput"
            type="text"
            placeholder={intl.formatMessage(messages.vendorConfigurationName)}
            value={vendorConfigurationName}
            onChange={(e) => setVendorConfigurationName(e.target.value)}
            style={{ width: 200, marginRight: 10 }}
          />
        )}
      </div>

      <ButtonWrapper>
        <Button
          data-testid="downloadButton"
          type="primary"
          onClick={onClickDownload}
          loading={props.downloading}
          icon={<DownloadOutlined />}
        >
          {intl.formatMessage(messages.export)}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default VendorStationsExport;
