import { Agreement } from "./Agreement";
import {
  AGREEMENT_DELETE_FAILED,
  AGREEMENT_DELETE_SUCCESS,
  AGREEMENTS_EXPORTED,
  AGREEMENTS_LOADED,
  CREATE_AGREEMENT,
  CREATE_AGREEMENT_FAILED,
  CREATE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT,
  EXPORT_AGREEMENTS,
  HIDE_CREATE_AGREEMENT_DIALOG,
  LOAD_AGREEMENTS,
  SHOW_CREATE_AGREEMENT_DIALOG,
} from "./constants";

export interface LoadAgreementsAction {
  type: typeof LOAD_AGREEMENTS;
}

export function loadAgreements(): LoadAgreementsAction {
  return {
    type: LOAD_AGREEMENTS,
  };
}

export interface AgreementsLoadedAction {
  type: typeof AGREEMENTS_LOADED;
  agreements: Array<Agreement>;
}

export function agreementsLoaded(
  agreements: Array<Agreement>
): AgreementsLoadedAction {
  return {
    type: AGREEMENTS_LOADED,
    agreements: agreements,
  };
}

export interface ShowCreateAgreementDialogAction {
  type: typeof SHOW_CREATE_AGREEMENT_DIALOG;
}

export function showCreateAgreementDialog(): ShowCreateAgreementDialogAction {
  return {
    type: SHOW_CREATE_AGREEMENT_DIALOG,
  };
}

export interface HideCreateAgreementDialogAction {
  type: typeof HIDE_CREATE_AGREEMENT_DIALOG;
}

export function hideCreateAgreementDialog(): HideCreateAgreementDialogAction {
  return {
    type: HIDE_CREATE_AGREEMENT_DIALOG,
  };
}

export interface CreateAgreementAction {
  type: typeof CREATE_AGREEMENT;
  agreement: Agreement;
}

export function createAgreement(agreement: Agreement): CreateAgreementAction {
  return {
    type: CREATE_AGREEMENT,
    agreement: agreement,
  };
}

export interface CreateAgreementSuccessAction {
  type: typeof CREATE_AGREEMENT_SUCCESS;
  payload: Agreement;
}

export function createAgreementSuccess(
  agreement: Agreement
): CreateAgreementSuccessAction {
  return {
    type: CREATE_AGREEMENT_SUCCESS,
    payload: agreement,
  };
}

export interface AgreementDeleteSuccessAction {
  type: typeof AGREEMENT_DELETE_SUCCESS;
}

export function agreementDeleteSuccess(): AgreementDeleteSuccessAction {
  return {
    type: AGREEMENT_DELETE_SUCCESS,
  };
}

export interface AgreementDeleteFailedAction {
  type: typeof AGREEMENT_DELETE_FAILED;
}

export function agreementDeleteFailed(): AgreementDeleteFailedAction {
  return {
    type: AGREEMENT_DELETE_FAILED,
  };
}

export interface CreateAgreementFailedAction {
  type: typeof CREATE_AGREEMENT_FAILED;
}

export function createAgreementFailed(): CreateAgreementFailedAction {
  return {
    type: CREATE_AGREEMENT_FAILED,
  };
}

export interface DeleteAgreementAction {
  type: typeof DELETE_AGREEMENT;
  payload: Agreement;
}

export function deleteAgreement(agreement: Agreement): DeleteAgreementAction {
  return {
    type: DELETE_AGREEMENT,
    payload: agreement,
  };
}

export interface ExportAgreementsAction {
  type: typeof EXPORT_AGREEMENTS;
}

export function exportAgreements(): ExportAgreementsAction {
  return {
    type: EXPORT_AGREEMENTS,
  };
}

export interface AgreementsExportedAction {
  type: typeof AGREEMENTS_EXPORTED;
}

export function agreementsExported(): AgreementsExportedAction {
  return {
    type: AGREEMENTS_EXPORTED,
  };
}
