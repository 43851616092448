import { Space } from "antd";
import styled from "styled-components";

export const BottomIndentWrapper = styled(Space)`
  margin-bottom: 16px;
`;

export const TopIndentWrapper = styled(Space)`
  margin-top: 16px;
`;

export const RightIndentWrapper = styled(Space)`
  margin-right: 16px;
`;
