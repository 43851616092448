import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment";
import { put, select, takeLatest } from "redux-saga/effects";
import { call, delay } from "typed-redux-saga";

import * as actions from "../../containers/FuelingProcessesPage/actions";
import { callApi } from "../../sagas";
import State from "../../State";
import { Cognito } from "../../utils/cognito";
import intl from "../../utils/intl";
import { setError } from "../App/actions";
import {
  EXPORT_FUELING_PROCESSES_TO_EXCEL,
  LOAD_FUELING_PROCESS_DETAILS,
  LOAD_FUELING_PROCESSES,
} from "./constants";
import messages from "./messages";
import { FuelingProcess, FuelingProcessDetail } from "./state";

type FuelingProcessesResponse = {
  processes: Array<FuelingProcessDto>;
  nextIterator?: string;
};

type FuelingProcessDto = {
  cardNumber: string;
  createTime: string;
  fuelingProcessId: string;
  payment?: {
    authorizedAmount: {
      amount: number;
      currency: string;
    };
    rrn: string;
    batch?: string;
  };
  productId: string;
  pumpNumber: string;
  receipt?: {
    totalAmount: {
      amount: number;
      currency: string;
    };
    quantity: number;
    price?: {
      amount: number;
      currency: string;
    };
  };
  requestedAmount: {
    amount: number;
    currency: string;
  };
  state: string;
  station: {
    brand: {
      code: string;
      name: string;
    };
    name: string;
    stationId: string;
    supplierId: string;
    vendor: string;
    vendorConfiguration: unknown;
  };
  rejection?: {
    code: string;
    description?: string;
  };
  clientId: string;
  partnerId: string;
  partnerRefuelingId: string;
  testMode: boolean;
};

const getAccessToken = (state: State): string =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  state.session.session!.credentials.accessToken;

const requestFuelingProcessDetails = async (
  axiosClient: AxiosInstance,
  fuelingProcessId: string,
  config: AxiosRequestConfig
): Promise<FuelingProcess> => {
  const response = await axiosClient.get<FuelingProcess>(
    `/support/processes/${fuelingProcessId}`,
    config
  );

  return {
    ...response.data,
    details: {
      ...response.data.details,
      createTime: moment(response.data.details.createTime).utc(),
    },
    events: response.data.events.map((event) => ({
      ...event,
      timestamp: moment(event.timestamp).utc(),
    })),
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* loadFuelingProcesses(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.LoadFuelingProcessesAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);
    const allFuelingProcesses: Array<FuelingProcessDetail> = [];

    let iterator: string | undefined;

    do {
      const requestConfig: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          cardNumber: action.filter.cardNumber,
          timeFrom: action.filter.dateTimeFrom?.toISOString(),
          timeTill: action.filter.dateTimeTill?.toISOString(),
          stationId: action.filter.stationId,
          iterator,
        },
      };

      const response: AxiosResponse<FuelingProcessesResponse> = yield callApi(
        cognito,
        accessToken,
        axiosClient.get,
        "/support/processes",
        requestConfig
      );

      const currentPageFuelingProcesses: Array<FuelingProcessDetail> =
        response.data.processes.map((fuelingProcess) => ({
          ...fuelingProcess,
          createTime: moment(fuelingProcess.createTime).utc(),
        }));

      allFuelingProcesses.push(...currentPageFuelingProcesses);

      iterator = response.data.nextIterator;
    } while (iterator);

    yield put(actions.fuelingProcessesLoaded(allFuelingProcesses));
  } catch (e) {
    yield put(actions.fuelingProcessesLoadingError());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* loadFuelingProcessDetails(
  axiousClient: AxiosInstance,
  cognito: Cognito,
  action: actions.LoadFuelingProcessAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response: FuelingProcess = yield callApi(
      cognito,
      accessToken,
      requestFuelingProcessDetails,
      axiousClient,
      action.fuelingProcessId,
      requestConfig
    );

    yield put(actions.fuelingProcessDetailsLoaded(response));
  } catch {
    yield put(actions.fuelingProcessDetailsLoadingError());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* watchLoadFuelingProcesses(
  axiousClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeLatest(
      LOAD_FUELING_PROCESSES,
      loadFuelingProcesses,
      axiousClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* watchLoadFuelingProcessDetails(
  axiousClient: AxiosInstance,
  cognito: Cognito
) {
  yield takeLatest(
    LOAD_FUELING_PROCESS_DETAILS,
    loadFuelingProcessDetails,
    axiousClient,
    cognito
  );
}

export function* watchExportProcessesToExcel(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeLatest(
      EXPORT_FUELING_PROCESSES_TO_EXCEL,
      exportFuelingProcessesToExcel,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* exportFuelingProcessesToExcel(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.ExportFuelingProcessesToExcelAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        cardNumber: action.filter.cardNumber,
        timeFrom: action.filter.dateTimeFrom?.toISOString(),
        timeTill: action.filter.dateTimeTill?.toISOString(),
        stationId: action.filter.stationId,
      },
    };

    const response: AxiosResponse<{ downloadUrl: string }> = yield callApi(
      cognito,
      accessToken,
      axiosClient.post,
      "/support/processes-export",
      null,
      requestConfig
    );

    const exportTimeout = moment.duration(5, "minutes");
    const startedAt = moment();

    for (;;) {
      // It would be better to use HEAD instead of GET, but it is not possible to generate a pre-signed URL for
      // both GET and HEAD. So we use GET and request only the first byte.
      const excelFileResponse: AxiosResponse = yield call(
        axiosClient.get,
        response.data.downloadUrl,
        {
          headers: {
            Range: "bytes=0-0",
          },
          validateStatus: () => true,
        }
      );
      if (excelFileResponse.status !== 404) break;
      if (moment().diff(startedAt) > exportTimeout.asMilliseconds()) {
        yield put(
          setError(intl.formatMessage(messages.exportProcessesToExcelTimeout))
        );
        yield put(actions.exportFuelingProcessesToExcelFailed());
        return;
      }

      yield call(delay, 1000);
    }

    yield put(
      actions.exportFuelingProcessesToExcelSuccess(response.data.downloadUrl)
    );
  } catch (e) {
    setError(e);
    yield put(actions.exportFuelingProcessesToExcelFailed());
  }
}
