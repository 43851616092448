import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { select, takeLatest } from "redux-saga/effects";
import { put, takeEvery } from "redux-saga/effects";
import { call } from "typed-redux-saga";

import { callApi } from "../../sagas";
import State from "../../State";
import { Cognito } from "../../utils/cognito";
import downloadFromBlob from "../../utils/downloadFromBlob";
import errors from "../../utils/errors";
import startDownload from "../../utils/startDownload";
import { setError } from "../App/actions";
import * as actions from "./actions";
import {
  ACTION_DOWNLOAD_STATIONS,
  ACTION_EXPORT_VENDOR_STATIONS,
  ACTION_SAVE_STATIONS,
  ACTION_UPLOAD_STATIONS,
} from "./constants";
import { Station } from "./Station";

interface ValidationResult {
  stations: Array<Station>;
  errors: Array<string>;
}

interface CreateStationImportResponse {
  id: string;
  uploadUrl: string;
}

interface CreateDownloadFileResponse {
  downloadUrl: string;
}

const getAccessToken = (state: State): string =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  state.session.session!.credentials.accessToken;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* uploadStationsSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.UploadStationsAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const createImportResponse: AxiosResponse<CreateStationImportResponse> =
      yield callApi(
        cognito,
        accessToken,
        axiosClient.post,
        "/admin/stations/import",
        null,
        requestConfig
      );

    yield call(
      axiosClient.put,
      createImportResponse.data.uploadUrl,
      action.content
    );
    const validateImportResponse: AxiosResponse<ValidationResult> =
      yield callApi(
        cognito,
        accessToken,
        axiosClient.post,
        `/admin/stations/import/${createImportResponse.data.id}/validate`,
        null,
        requestConfig
      );
    if (validateImportResponse.data.errors.length === 0) {
      yield put(
        actions.stationsUploadSucceeded(
          validateImportResponse.data.stations,
          createImportResponse.data.id
        )
      );
    } else {
      yield put(
        actions.stationsUploadFailed(validateImportResponse.data.errors)
      );
    }
  } catch (e) {
    const message = errors.toString(e);
    yield put(setError(`unable to upload stations: ${message}`));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* watchUploadStations(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeLatest(
      ACTION_UPLOAD_STATIONS,
      uploadStationsSaga,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* saveStationsSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.SaveStationsAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    yield callApi(
      cognito,
      accessToken,
      axiosClient.post,
      `/admin/stations/import/${action.stationImportId}/save`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    yield put(actions.stationsSaved());
  } catch (e) {
    const message = errors.toString(e);
    yield put(setError(`unable to save stations: ${message}`));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* watchSaveStations(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeEvery(
      ACTION_SAVE_STATIONS,
      saveStationsSaga,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* downloadAllStationsSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const response: AxiosResponse<CreateDownloadFileResponse> = yield callApi(
      cognito,
      accessToken,
      axiosClient.post,
      "/admin/stations/export",
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    yield put(actions.downloadStationsStarted());

    yield call(startDownload, response.data.downloadUrl);
  } catch (e) {
    const message = errors.toString(e);
    yield put(setError(`unable to download the stations: ${message}`));
  }
}

export function* watchDownloadAllStations(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeEvery(
      ACTION_DOWNLOAD_STATIONS,
      downloadAllStationsSaga,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* exportVendorStationsSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.ExportVendorStationsAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const response: AxiosResponse<Blob> = yield callApi(
      cognito,
      accessToken,
      axiosClient.get,
      "/admin/vendorstations/export?vendor=" +
        action.vendor.toString() +
        "&vendorConfigurationName=" +
        action.vendorConfigurationName,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      }
    );

    yield call(downloadFromBlob, response);

    yield put(actions.vendorStationsDownloaded());
  } catch (e) {
    const message = errors.toString(e);
    yield put(setError(`unable to download vendor stations: ${message}`));
    yield put(actions.vendorStationsExportFailed());
  }
}

export function* watchExportVendorStations(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeEvery(
      ACTION_EXPORT_VENDOR_STATIONS,
      exportVendorStationsSaga,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}
