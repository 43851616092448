import { Table } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";

import intl from "../../../../utils/intl";
import messages from "../../messages";
import { StationMart } from "../../StationMart";
import StationMartsTableProps from "./props";

const StationMartsTable: React.FC<StationMartsTableProps> = (
  props: StationMartsTableProps
) => {
  const paginationOptions = {
    total: props.stationMarts.length,
    showTotal: (total: number, range: [number, number]): React.ReactNode =>
      `${range[0]}-${range[1]} of ${total}`,
  };

  return (
    <Table
      dataSource={props.stationMarts}
      scroll={{ x: "max-content" }}
      rowKey={(sm) => `${sm.stationId}_${sm.nextMartId}`}
      pagination={paginationOptions}
    >
      <Column
        width={200}
        sorter={true}
        title={intl.formatMessage(messages.stationId)}
        render={(record: StationMart): React.ReactNode => record.stationId}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.nextMartId)}
        render={(record: StationMart): React.ReactNode => record.nextMartId}
      />
      <Column
        width={200}
        title={intl.formatMessage(messages.fromDate)}
        render={(record: StationMart): React.ReactNode => record.fromDate}
      />
    </Table>
  );
};

export default StationMartsTable;
