import { SET_ERROR } from "../App/constants";
import {
  ACTION_COMPLETE_STATIONS_UPLOAD,
  ACTION_DOWNLOAD_STATIONS,
  ACTION_DOWNLOAD_STATIONS_STARTED,
  ACTION_EXPORT_VENDOR_STATIONS,
  ACTION_EXPORT_VENDOR_STATIONS_FAILED,
  ACTION_SAVE_STATIONS,
  ACTION_STATIONS_SAVED,
  ACTION_UPLOAD_STATIONS,
  ACTION_UPLOAD_STATIONS_FAILED,
  ACTION_UPLOAD_STATIONS_SUCCEEDED,
  ACTION_VENDOR_STATIONS_DOWNLOADED,
} from "./constants";
import StationsPageState, {
  ExportVendorStationsState,
  ImportExportStationsState,
} from "./state";

export const importExportStationsInitialState: ImportExportStationsState = {
  type: "initial",
};

export const exportVendorStationsInitialState: ExportVendorStationsState = {
  downloading: false,
};

export const stationsPageInitialState: StationsPageState = {
  importExportStationsState: importExportStationsInitialState,
  exportVendorStationsState: exportVendorStationsInitialState,
};

export const importExportStationsReducer = (
  state: ImportExportStationsState = importExportStationsInitialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): ImportExportStationsState => {
  switch (action.type) {
    case ACTION_UPLOAD_STATIONS:
      return {
        type: "uploading",
      };

    case ACTION_UPLOAD_STATIONS_SUCCEEDED:
      return {
        type: "upload-succeeded",
        stations: action.stations,
        stationImportId: action.stationImportId,
      };

    case ACTION_UPLOAD_STATIONS_FAILED:
      return {
        type: "upload-failed",
        errors: action.errors,
      };

    case ACTION_COMPLETE_STATIONS_UPLOAD:
      return {
        type: "initial",
      };

    case ACTION_SAVE_STATIONS:
      return {
        type: "saving",
        totalCount:
          state.type == "upload-succeeded" ? state.stations.length : 0,
      };

    case ACTION_STATIONS_SAVED:
      return {
        type: "saved",
        totalCount: state.type == "saving" ? state.totalCount : 0,
      };

    case ACTION_DOWNLOAD_STATIONS:
      return {
        type: "downloading",
      };

    case ACTION_DOWNLOAD_STATIONS_STARTED:
      return {
        type: "initial",
      };

    case SET_ERROR: {
      return {
        type: "initial",
      };
    }
    default:
      break;
  }

  return state;
};

export const exportVendorStationsReducer = (
  state: ExportVendorStationsState = exportVendorStationsInitialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): ExportVendorStationsState => {
  switch (action.type) {
    case ACTION_EXPORT_VENDOR_STATIONS:
      return {
        downloading: true,
      };
    case ACTION_VENDOR_STATIONS_DOWNLOADED:
      return {
        downloading: false,
      };
    case ACTION_EXPORT_VENDOR_STATIONS_FAILED: {
      return {
        downloading: false,
      };
    }
    default:
      break;
  }

  return state;
};
