import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import { Button, Popconfirm } from "antd";
import React from "react";

import intl from "../../../../utils/intl";
import messages from "./messages";
import ActionsCellProps from "./props";

const ActionsCell: React.FC<ActionsCellProps> = (props: ActionsCellProps) => {
  return (
    <Popconfirm
      title={intl.formatMessage(messages.deleteConfirmationTitle)}
      onConfirm={() => props.delete(props.record)}
      okText={intl.formatMessage(messages.deleteConfirmationYesTitle)}
      cancelText={intl.formatMessage(messages.deleteConfirmationNoTitle)}
      placement="leftBottom"
    >
      <Button icon={<DeleteOutlined />} loading={props.deleting} />
    </Popconfirm>
  );
};

export default ActionsCell;
