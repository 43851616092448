import React from "react";
import { FormattedMessage } from "react-intl";

import messages from "./messages";

export default function NotFound(): JSX.Element {
  return (
    <article>
      <div>
        <FormattedMessage {...messages.header} />
      </div>
    </article>
  );
}
