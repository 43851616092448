import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Descriptions, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import CardNumber from "../../../../components/CardNumber";
import intl from "../../../../utils/intl";
import messages from "./messages";
import CardProps from "./props";

const StyledDescriptions = styled(Descriptions)`
  th {
    width: 20%;
  }
`;

const Card: React.FC<CardProps> = (props: CardProps) => {
  const statusId = "cardStatus";
  const cardStatus = props.hasRegisteredSessions ? (
    <Tag id={statusId} icon={<CheckCircleOutlined />} color="success">
      {intl.formatMessage(messages.activeCard)}
    </Tag>
  ) : (
    <Tag id={statusId} icon={<CloseCircleOutlined />} color="error">
      {intl.formatMessage(messages.inactiveCard)}
    </Tag>
  );

  return (
    <>
      <StyledDescriptions column={1} bordered={true}>
        <Descriptions.Item label={intl.formatMessage(messages.cardNumberTitle)}>
          <CardNumber cardNumber={props.cardNumber} />
        </Descriptions.Item>
        <Descriptions.Item label={intl.formatMessage(messages.statusTitle)}>
          {cardStatus}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage(messages.phoneNumberTitle)}
        >
          {props.phoneNumber}
        </Descriptions.Item>
      </StyledDescriptions>

      <br />

      <Link to={`/processes?cardNumber=${props.cardNumber}`}>
        {intl.formatMessage(messages.showFuelingProcesses)}
      </Link>
    </>
  );
};

export default Card;
