import {
  CognitoAuth,
  CognitoAuthOptions,
  CognitoAuthSession,
} from "amazon-cognito-auth-js";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import jwt from "jsonwebtoken";

import config, { CognitoConfiguration } from "./config";

export interface CognitoCredentials {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}

export interface CognitoUser {
  userName: string;
  email: string;
}

export interface CongnitoSession {
  credentials: CognitoCredentials;
  user: CognitoUser;
}

const getCognitoSignInUri = (cognitoConfig: CognitoConfiguration): string => {
  const signinUri = `https://${cognitoConfig.appWebDomain}/login?response_type=code&client_id=${cognitoConfig.clientId}&redirect_uri=${cognitoConfig.redirectUriSignIn}`;
  return signinUri;
};

const parseCognitoWebResponse = (href: string): Promise<CognitoAuthSession> => {
  return new Promise((resolve, reject) => {
    const options: CognitoAuthOptions = {
      ClientId: config.cognito.clientId,
      AppWebDomain: config.cognito.appWebDomain,
      RedirectUriSignIn: config.cognito.redirectUriSignIn,
      RedirectUriSignOut: config.cognito.redirectUriSignOut,
      TokenScopesArray: config.cognito.tokenScopesArray,
    };
    const auth = new CognitoAuth(options);
    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result): void {
        resolve(result);
      },
      onFailure: function (err): void {
        reject(new Error("Failure parsing Cognito web response: " + err));
      },
    };
    auth.parseCognitoWebResponse(href);
  });
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const getSession = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.userPoolId,
      ClientId: config.cognito.clientId,
    });
    const cognitoUser = userPool.getCurrentUser();
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    cognitoUser!.getSession(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (err: any, result: any) => {
        if (err || !result) {
          reject(new Error("Failure getting Cognito session: " + err));
          return;
        }
        // Resolve the promise with the session credentials

        resolve(result);
      }
    );
  });
};

const getCognitoSession = async (): Promise<CongnitoSession> => {
  const session = await getSession();
  return {
    credentials: {
      accessToken: session.accessToken.jwtToken,
      idToken: session.idToken.jwtToken,
      refreshToken: session.refreshToken.token,
    },
    user: {
      userName: session.idToken.payload["cognito:username"],
      email: session.idToken.payload.email,
    },
  };
};

const isValidAccessToken = (accessToken: string): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const token: any = jwt.decode(accessToken);
  if (token == null || typeof token === "string") {
    return false;
  }
  const exp = token.exp === undefined ? 0 : (token.exp as number);
  return Date.now() < exp * 1000;
};

export interface Cognito {
  getCognitoSignInUri(cognitoConfig: CognitoConfiguration): string;
  parseCognitoWebResponse(href: string): Promise<CognitoAuthSession>;
  getCognitoSession(): Promise<CongnitoSession>;
  isValidAccessToken(accessToken: string): boolean;
}

const cognito: Cognito = {
  getCognitoSignInUri,
  parseCognitoWebResponse,
  getCognitoSession,
  isValidAccessToken,
};

export default cognito;
