import { defineMessages } from "react-intl";

const scope = "supportui.containers.CardPage";

export default defineMessages({
  cardNotFound: {
    id: `${scope}.cardNotFound`,
    defaultMessage: "Card not found",
  },
  cardInformationTitle: {
    id: `${scope}.cardInformationTitle`,
    defaultMessage: "Card information",
  },
});
