import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { select, takeLatest } from "redux-saga/effects";
import { put, takeEvery } from "redux-saga/effects";
import { call } from "typed-redux-saga";

import { callApi } from "../../sagas";
import State from "../../State";
import { Cognito } from "../../utils/cognito";
import errors from "../../utils/errors";
import startDownload from "../../utils/startDownload";
import { setError } from "../App/actions";
import * as actions from "./actions";
import {
  ACTION_DOWNLOAD_STATION_MARTS,
  ACTION_SAVE_STATION_MARTS,
  ACTION_UPLOAD_STATION_MARTS,
} from "./constants";
import { StationMart } from "./StationMart";

interface ValidationResult {
  martStations: Array<StationMart>;
  errors: Array<string>;
}

interface CreateStationMartImportResponse {
  id: string;
  url: string;
}

interface CreateDownloadFileResponse {
  downloadUrl: string;
}

const getAccessToken = (state: State): string =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  state.session.session!.credentials.accessToken;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* uploadStationMartsSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.UploadStationMartsAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const createImportResponse: AxiosResponse<CreateStationMartImportResponse> =
      yield callApi(
        cognito,
        accessToken,
        axiosClient.post,
        "/tinkoff/station-marts/import",
        null,
        requestConfig
      );

    yield call(axiosClient.put, createImportResponse.data.url, action.content);
    const validateImportResponse: AxiosResponse<ValidationResult> =
      yield callApi(
        cognito,
        accessToken,
        axiosClient.post,
        `/tinkoff/station-marts/${createImportResponse.data.id}/validate`,
        null,
        requestConfig
      );

    if (validateImportResponse.data.errors.length === 0) {
      yield put(
        actions.stationMartsUploadSucceeded(
          validateImportResponse.data.martStations,
          createImportResponse.data.id
        )
      );
    } else {
      yield put(
        actions.stationMartsUploadFailed(validateImportResponse.data.errors)
      );
    }
  } catch (e) {
    const message = errors.toString(e);
    yield put(setError(`unable to upload station marts: ${message}`));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* watchUploadStationMarts(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeLatest(
      ACTION_UPLOAD_STATION_MARTS,
      uploadStationMartsSaga,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* saveStationMartsSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: actions.SaveStationMartsAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    yield callApi(
      cognito,
      accessToken,
      axiosClient.post,
      `/tinkoff/station-marts/${action.stationMartsImportId}/save`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    yield put(actions.stationMartsSaved());
  } catch (e) {
    const message = errors.toString(e);
    yield put(setError(`unable to save station marts: ${message}`));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* watchSaveStationMarts(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeEvery(
      ACTION_SAVE_STATION_MARTS,
      saveStationMartsSaga,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* downloadAllStationMartsSaga(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const response: AxiosResponse<CreateDownloadFileResponse> = yield callApi(
      cognito,
      accessToken,
      axiosClient.post,
      "/tinkoff/station-marts/export",
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    yield put(actions.downloadStationMartsStarted());

    yield call(startDownload, response.data.downloadUrl);
  } catch (e) {
    const message = errors.toString(e);
    yield put(setError(`unable to download the station marts: ${message}`));
  }
}

export function* watchDownloadAllStationMarts(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  try {
    yield takeEvery(
      ACTION_DOWNLOAD_STATION_MARTS,
      downloadAllStationMartsSaga,
      axiosClient,
      cognito
    );
  } catch (error) {
    yield put(setError(error));
  }
}
