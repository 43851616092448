import { defineMessages } from "react-intl";

const scope = "supportui.containers.FuelingProcessDetailEvents";

export default defineMessages({
  logsTitle: {
    id: `${scope}.logsTitle`,
    defaultMessage: "Logs",
  },
});
