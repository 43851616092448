import { SET_ERROR } from "../App/constants";
import {
  ACTION_COMPLETE_STATION_MARTS_UPLOAD,
  ACTION_DOWNLOAD_STATION_MARTS,
  ACTION_DOWNLOAD_STATION_MARTS_STARTED,
  ACTION_SAVE_STATION_MARTS,
  ACTION_STATION_MARTS_SAVED,
  ACTION_UPLOAD_STATION_MARTS,
  ACTION_UPLOAD_STATION_MARTS_FAILED,
  ACTION_UPLOAD_STATION_MARTS_SUCCEEDED,
} from "./constants";
import StationMartsPageState from "./state";

const initialState: StationMartsPageState = {
  type: "initial",
};

const stationMartsReducer = (
  state: StationMartsPageState = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
): StationMartsPageState => {
  switch (action.type) {
    case ACTION_UPLOAD_STATION_MARTS:
      return {
        type: "uploading",
      };

    case ACTION_UPLOAD_STATION_MARTS_SUCCEEDED:
      return {
        type: "upload-succeeded",
        stationMarts: action.stationMarts,
        stationMartsImportId: action.stationMartsImportId,
      };

    case ACTION_UPLOAD_STATION_MARTS_FAILED:
      return {
        type: "upload-failed",
        errors: action.errors,
      };

    case ACTION_COMPLETE_STATION_MARTS_UPLOAD:
      return {
        type: "initial",
      };

    case ACTION_SAVE_STATION_MARTS:
      return {
        type: "saving",
        totalCount:
          state.type == "upload-succeeded" ? state.stationMarts.length : 0,
      };

    case ACTION_STATION_MARTS_SAVED:
      return {
        type: "saved",
        totalCount: state.type == "saving" ? state.totalCount : 0,
      };
    case ACTION_DOWNLOAD_STATION_MARTS:
      return {
        type: "downloading",
      };
    case ACTION_DOWNLOAD_STATION_MARTS_STARTED:
      return {
        type: "initial",
      };
    case SET_ERROR: {
      return {
        type: "initial",
      };
    }
    default:
      break;
  }

  return state;
};

export { initialState };
export default stationMartsReducer;
