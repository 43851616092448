import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { put, select, takeLatest } from "redux-saga/effects";

import { callApi } from "../../sagas";
import State from "../../State";
import { Cognito } from "../../utils/cognito";
import {
  SearchCardsAction,
  searchCardsFailed,
  searchCardsSucceeded,
} from "./actions";
import { SEARCH_CARDS } from "./constants";

const getAccessToken = (state: State): string =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  state.session.session!.credentials.accessToken;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* seachCard(
  axiosClient: AxiosInstance,
  cognito: Cognito,
  action: SearchCardsAction
) {
  try {
    const accessToken: string = yield select(getAccessToken);

    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        cardNumber: action.cardNumber,
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: AxiosResponse = yield callApi(
      cognito,
      accessToken,
      axiosClient.get,
      "/auth/support/users",
      requestConfig
    );

    yield put(searchCardsSucceeded(response.data));
  } catch {
    yield put(searchCardsFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* watchSearchCard(
  axiosClient: AxiosInstance,
  cognito: Cognito
) {
  yield takeLatest(SEARCH_CARDS, seachCard, axiosClient, cognito);
}
