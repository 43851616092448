import errors from "../../utils/errors";
import { SET_ERROR } from "./constants";

export interface ErrorAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: string;
  type: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setError = (msg: any): ErrorAction => {
  const message = errors.toString(msg);
  return {
    message,
    type: SET_ERROR,
  };
};
