import { Col, Row, Space, Typography } from "antd";
import React from "react";
import styled from "styled-components";

import Menu from "../Menu";
import HeaderProps from "./props";

const { Text } = Typography;

const HeaderText = styled(Text)`
  color: #fff;
`;

const LabelLogo = styled(HeaderText)`
  color: #fff;
  font-size: 16pt;
`;

const UserInfo = styled(HeaderText)`
  height: 100%;
  width: 100%;
  float: right;
`;

const LabelImage = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/gas_station.svg"});
  height: 64px;
  width: 64px;
`;

const UserInfoSpace = styled(Space)`
  height: 100%;
  float: right;
`;

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  return (
    <Row align="bottom">
      <Col>
        <LabelImage />
      </Col>
      <Col>
        <LabelLogo> Support UI for Neo </LabelLogo>
      </Col>
      <Col offset={1} flex="auto">
        <Menu location={props.location} />
      </Col>
      <Col flex="auto">
        <UserInfoSpace>
          <UserInfo>{props.userName}</UserInfo>
        </UserInfoSpace>
      </Col>
    </Row>
  );
};

export default Header;
