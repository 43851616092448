import { defineMessages } from "react-intl";

const scope = "supportui.components.Menu";

export default defineMessages({
  card: {
    id: `${scope}.card`,
    defaultMessage: "Card",
  },
  fuelingProcesses: {
    id: `${scope}.fuelingProcesses`,
    defaultMessage: "Fueling processes",
  },
  stations: {
    id: `${scope}.stations`,
    defaultMessage: "Stations",
  },
  agreements: {
    id: `${scope}.agreements`,
    defaultMessage: "Agreements",
  },
  stationMarts: {
    id: `${scope}.stationMarts`,
    defaultMessage: "Marts",
  },
});
