import { defineMessages } from "react-intl";

const scope = "supportui.containers.FuelingProcesses";

export default defineMessages({
  cardNumberLabel: {
    id: `${scope}.cardNumberLabel`,
    defaultMessage: "Card number",
  },
  cardNumberPlaceholder: {
    id: `${scope}.cardNumberPlaceholder`,
    defaultMessage: "Enter digits only",
  },
  inputCardNumberMessage: {
    id: `${scope}.inputCardNumberMessage`,
    defaultMessage: "Please input card number (digits only)",
  },
  stationIdLabel: {
    id: `${scope}.stationIdLabel`,
    defaultMessage: "Station",
  },
  stationIdPlaceholder: {
    id: `${scope}.stationIdPlaceholder`,
    defaultMessage: "e.g. MP3434",
  },
  fromDateLabel: {
    id: `${scope}.fromDateLabel`,
    defaultMessage: "From date, UTC",
  },
  tillDateLabel: {
    id: `${scope}.tillDateLabel`,
    defaultMessage: "To date, UTC",
  },
  searchButtonText: {
    id: `${scope}.searchButtonText`,
    defaultMessage: "Search",
  },
});
