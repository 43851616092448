import { defineMessages } from "react-intl";

const scope = "supportui.components.AgreementsPage";

export default defineMessages({
  createAgreement: {
    id: `${scope}.createAgreement`,
    defaultMessage: "Create",
  },
  unableToCreateAgreement: {
    id: `${scope}.unableToCreateAgreement`,
    defaultMessage: "unable to create supplier agreement:",
  },
  agreementConflict: {
    id: `${scope}.agreementConflict`,
    defaultMessage:
      "supplier agreement already exists for this supplier and card issuer",
  },
  exportAgreements: {
    id: `${scope}.exportAgreements`,
    defaultMessage: "Export",
  },
});
