export const ACTION_UPLOAD_STATION_MARTS =
  "supportui/StationMartsPage/ACTION_UPLOAD_STATION_MARTS";
export const ACTION_UPLOAD_STATION_MARTS_SUCCEEDED =
  "supportui/StationMartsPage/ACTION_UPLOAD_STATION_MARTS_SUCCEEDED";
export const ACTION_UPLOAD_STATION_MARTS_FAILED =
  "supportui/StationMartsPage/ACTION_UPLOAD_STATION_MARTS_FAILED";
export const ACTION_SAVE_STATION_MARTS =
  "supportui/StationMartsPage/ACTION_SAVE_STATION_MARTS";
export const ACTION_STATION_MARTS_SAVED =
  "supportui/StationMartsPage/ACTION_STATION_MARTS_SAVED";
export const ACTION_COMPLETE_STATION_MARTS_UPLOAD =
  "supportui/StationMartsPage/ACTION_COMPLETE_STATION_MARTS_UPLOAD";
export const ACTION_DOWNLOAD_STATION_MARTS =
  "supportui/StationMartsPage/ACTION_DOWNLOAD_STATION_MARTS";
export const ACTION_DOWNLOAD_STATION_MARTS_STARTED =
  "supportui/StationMartsPage/ACTION_DOWNLOAD_STATION_MARTS_STARTED";
